import React from "react";
import './App.css';
import Knackroutes from "./Routes/knackroutes";


const App = () => {
  return (
   <>
   <Knackroutes />
   </>
      
    
  );
};

export default App;
