import React from 'react';
import Header from '../header';
import Footer from '../footer';
import image from '../../src/assets/images/mainbanner.png'
import image1 from '../../src/assets/images/baner1 (1).jpg'
const Aboutus = () => {
return (
<div>
   <Header/>
   <section  id="banner" style={{ 
   backgroundColor: 'transparent',
   backgroundImage: 'radial-gradient(at bottom right, #3758FF7D 0%, #030510 64%)'
   }}>
   <div class="container pt-5">
      <div class="elementor-background-overlay" style={{
      backgroundImage: 'url(https://kit.baliniz.com/virtty/wp-content/uploads/sites/55/2023/05/noise.png)',
      backgroundPosition: 'center center',
      backgroundRepeat: 'repeat',
      backgroundSize: '14% auto',
      opacity: 0.5,
      transition: 'background 0.3s, border-radius 0.3s, opacity 0.3s'
      }}>
   </div>
   <h2 class="headabout" >
      <span class="dynamic-wrapper style-gradient">
      <span class="dynamic-text" Alt="Anout Kanckhook">ABOUT KNACKHOOK</span> 
      </span>
      <p style={{fontSize:"17px"}} class="mt-3">KnackHook, The Genius Network, is helmed by a cadre of IT experts since 2016. Our mission is to amplify the value of your resources and investments. With a worldwide delivery model, we guarantee client contentment.</p>
      <span class="normal-text style-gradient" ></span>
   </h2>
</div>
</section>
<div className="container mt-5 ">
   <div style={{borderLeft:'4px solid #379CFFFC'}}>
   <p style={{paddingLeft:'30px'}}>KnackHook is a prominent provider of comprehensive corporate solutions, offering a diverse range of services such as software development, recruitment solutions, and personalized learning programs tailored for both core software and emerging technologies.</p>
</div>
<div style={{borderLeft:'4px solid #379CFFFC'}}>
<p style={{paddingLeft:'30px'}}>With a strong emphasis on excellence, we excel in delivering top-tier services to clients across the globe, assisting them in navigating and thriving within the dynamic landscape of modern business competition.</p>
</div>
</div>
<div className="container mb-5">
   <div className="row mt-5">
      <div className="col-sm-12 col-md-6 col-xl-6 col-lg-6">
         <div className="card p-3 mt-3" style={{background:'transparent',border:'1px solid grey',borderRadius:'30px',height:'150px'}}>
      <div style={{ display: 'flex' }}>

            <div style={{color: '#fff',
            borderStyle:'solid',
            borderWidth: '1px 1px 1px 1px',
            borderColor: '#379CFFFC',
            borderRadius: '50%',
            height:'45px',
            width:'45px',
            boxShadow: '0px 0px 6px 0px rgba(54.99999999999997, 156.00000000000023, 255, 0.9882352941176471)',
            margin: '0px 8px 0px 0px',
            padding: '10px'}}>
            <i class="fa fa-eye " aria-hidden="true" style={{color: '#fff',marginLeft:'5px',marginRight:'5px'}}></i>
         </div>
         <div style={{marginLeft:'30px'}}>
           <h5> Our Vision</h5>
          <p > To lead transformative growth in the digital realm through innovative solutions and unparalleled expertise.</p> 
         </div>
         </div>
      </div>
   
   </div>
   <div className="col-sm-12 col-md-6 col-xl-6 col-lg-6">
         <div className="card p-3 mt-3" style={{background:'transparent',border:'1px solid grey',borderRadius:'30px',height:'150px'}}>
      <div style={{ display: 'flex' }}>

            <div style={{color: '#fff',
            borderStyle:'solid',
            borderWidth: '1px 1px 1px 1px',
            borderColor: '#379CFFFC',
            borderRadius: '50%',
            height:'45px',
            width:'45px',
            boxShadow: '0px 0px 6px 0px rgba(54.99999999999997, 156.00000000000023, 255, 0.9882352941176471)',
            margin: '0px 8px 0px 0px',
            padding: '10px'}}>
            <i class="fa fa-external-link " aria-hidden="true" style={{color: '#fff',marginLeft:'5px',marginRight:'5px'}}></i>
         </div>
         <div style={{marginLeft:'30px'}}>
           <h5> Our Mision</h5>
          <p >To empower businesses with transformative solutions and strategic guidance, driving sustainable growth and innovation in the digital age.</p> 
         </div>
         </div>
      </div>
   
   </div>
</div>
</div>
<Footer/>
</div>
);
};
export default Aboutus;