import React, { useState ,useEffect} from 'react';
import img from '../../src/assets/images/anchorr1 (1).jpg'
import img1 from '../../src/assets/images/laterentry (1).jpg'
import img2 from '../../src/assets/images/signinsoft (1).jpg'



const Portfoliohome = () => {
   
    const [activeCategory, setActiveCategory] = useState('finance');

    const showPortfolio = (category) => {
      setActiveCategory(category);
    };
  return (

<div  id="Portfolio" class="container-xxl py-5" >

<div >
<div class="container">
<div class="row">
       <div class="col-1">
          <div class="head-a">
             <div class="head-b" >
                <h5 class="elementor-heading-title elementor-size-default" style={{color: "#379CFFFC"}}>PORTFOLIO</h5>
             </div>
          </div>
       </div>
       <div class="col-11">
          <div>
             <div class="head2a"style={{textAlign:"left"}}>
                <h2 class="head2b" >
                   <span class="dynamic-wrapper style-gradient">
                   <span class="dynamic-text" >Our Latest Portfolio</span></span>
                   <span class="normal-text style-gradient" ></span>
                </h2>
             </div>
          </div>
       </div>
    </div>
    <ul class="nav nav-pills">
    {/* <li className="nav-item">
          <button
            type="button"
            className={`btn btn-primary mt-3 ${activeCategory === 'finance' ? 'active' : ''}`}
            style={{ marginRight: '10px',backgroundColor:"#379CFFFC" }}
            onClick={() => showPortfolio('finance')}
          >
            <a style={{color:"#020510" }}class="nav-link" aria-current="page"><b>Finance</b> </a>
          </button>
        </li>
        <li className="nav-item">
          <button
            type="button"
            className={`btn btn-primary mt-3 ${activeCategory === 'education' ? 'active' : ''}`}
            style={{ marginRight: '10px' }}
            onClick={() => showPortfolio('education')}
          >
            <a class="nav-link" aria-current="page"><b> Education</b></a>
          </button>
        </li> */}
        <li className="nav-item">
            <button
              type="button"
              className={`btn btn-primary mt-3 ${
                activeCategory === 'finance' ? 'active' : ''
              }`}
              style={{
                marginRight: '10px',
                backgroundColor:
                  activeCategory === 'finance' ? '#379CFFFC' : '',
              }}
              onClick={() => showPortfolio('finance')}
            >
              <a class="nav-link" aria-current="page" style={{ color: activeCategory === 'finance' ? '#020510' : '' }}>
                <b>Finance</b>
              </a>
            </button>
          </li>
          <li className="nav-item">
            <button
              type="button"
              className={`btn btn-primary mt-3 ${
                activeCategory === 'education' ? 'active' : ''
              }`}
              style={{
                marginRight: '10px',
                backgroundColor:
                  activeCategory === 'education' ? '#379CFFFC' : '',
              }}
              onClick={() => showPortfolio('education')}
            >
              <a class="nav-link" aria-current="page" style={{ color: activeCategory === 'education' ? '#020510' : '' }}>
                <b> Education</b>
              </a>
            </button>
          </li>
        {/* <li className="nav-item">
          <button
            type="button"
            className={`btn btn-primary mt-3 ${activeCategory === 'Travel&Hospitality' ? 'active' : ''}`}
            style={{ marginRight: '10px' }}
            onClick={() => showPortfolio('Travel&Hospitality')}
          >
            <a class="nav-link" aria-current="page"><b>Travel & Hospitality</b> </a>
          </button>
        </li>
        <li className="nav-item">
          <button
            type="button"
            className={`btn btn-primary mt-3 ${activeCategory === 'Retail&Ecommerce' ? 'active' : ''}`}
            style={{ marginRight: '10px' }}
            onClick={() => showPortfolio('Retail&Ecommerce')}
          >
            <a class="nav-link" aria-current="page"><b>Retail & Ecommerce</b></a>
           
          </button>
        </li> */}
        <li className="nav-item">
  <button
    type="button"
    className={`btn btn-primary mt-3 ${
      activeCategory === 'Travel&Hospitality' ? 'active' : ''
    }`}
    style={{
      marginRight: '10px',
      backgroundColor:
        activeCategory === 'Travel&Hospitality' ? '#379CFFFC' : '',
    }}
    onClick={() => showPortfolio('Travel&Hospitality')}
  >
    <a class="nav-link" aria-current="page" style={{ color: activeCategory === 'Travel&Hospitality' ? '#020510' : '' }}>
      <b>Travel & Hospitality</b>
    </a>
  </button>
</li>
<li className="nav-item">
  <button
    type="button"
    className={`btn btn-primary mt-3 ${
      activeCategory === 'Retail&Ecommerce' ? 'active' : ''
    }`}
    style={{
      marginRight: '10px',
      backgroundColor:
        activeCategory === 'Retail&Ecommerce' ? '#379CFFFC' : '',
    }}
    onClick={() => showPortfolio('Retail&Ecommerce')}
  >
    <a class="nav-link" aria-current="page" style={{ color: activeCategory === 'Retail&Ecommerce' ? '#020510' : '' }}>
      <b>Retail & Ecommerce</b>
    </a>
  </button>
</li>

  
</ul>
<div class="row gy-2 gx-4">
<div className="row gy-2 gx-4">
        <div
          className={`col-md-6 col-lg-4 wow fadeInUp ${activeCategory !== 'finance' ? 'd-none' : ''}`}
          data-wow-delay="0.1s"
        >
        
        <div class="service-item">
            <img style={{borderRadius:"30px",objectFit:"cover",overflow:"hidden"}} class="img-fluid" src={img} alt="Anchorewellness"/>
           
            <div class="service-detail">
                <div class="service-title">
                    <hr style={{color:"#379cff"}}  class="w-25"/>
                    <h3 style={{color:"#379cff"}} class="mb-0">Anchor Wellness</h3>
                    <hr style={{color:"#379cff"}}   class="w-25"/>
                    <p style={{color:"white",float:"right",fontStyle:"oblique"}} class="mb-0">Revolutionary Healers</p>

                </div>
                <div class="service-text">
                    <p class="text-white mb-0 mt-5">Anchor Wellness Center revolutionizes healthcare with integrated, evidence-informed services, prioritizing continuity of care. Our expert team creates a transformative, holistic approach to optimize physical, mental, and nutritional health, embodying hope for our community.</p>
                </div>
            </div>
        </div>
  
        </div>
        <div
          className={`col-md-6 col-lg-4 wow fadeInUp ${activeCategory !== 'finance' ? 'd-none' : ''}`}
          data-wow-delay="0.1s"
        >
          <div class="service-item">
            <img class="img-fluid" Alt="Later Entry"src={img1} alt="LaterEntry"/>
            
            <div class="service-detail">
                <div class="service-title">
                    <hr style={{color:"#379cff"}} class="w-25"/>
                    <h3 style={{color:"#379cff"}} class="mb-0">LaterEntry</h3>
                    <hr style={{color:"#379cff"}} class="w-25"/>
                    <p style={{color:"white",float:"right",fontStyle:"oblique"}} class="mb-0"> Unlocking Your Second Chance</p>
                </div>
                <div class="service-text">
                    <p class="text-white mb-0 mt-5">LaterEntry is an online platform that offers guidance to people looking to restart their career at every step, from resume building to facing interviews to finding a job. Register to find opportunities from professionals who are willing to help you succeed with a second chance.</p>
                </div>
            </div>
        </div>
        </div>
        <div
          className={`col-md-6 col-lg-4 wow fadeInUp ${activeCategory !== 'finance' ? 'd-none' : ''}`}
          data-wow-delay="0.3s"
        >
        <div class="service-item">
            <img class="img-fluid" Alt="signinsoft" src={img2} alt="Signinsoft"/>
           
            <div class="service-detail">
                <div class="service-title">
                <hr style={{color:"#379cff"}}  class="w-25"/>
                    <h3 style={{color:"#379cff"}} class="mb-0">Signin Soft</h3>
                    <hr style={{color:"#379cff"}}  class="w-25"/>
                    <p style={{color:"white",float:"right",fontStyle:"oblique"}} class="mb-0">Get into your Future</p>

                </div>
                <div class="service-text">
                    <p class="text-white mb-0 mt-5">Signin Soft Inc. is founded with a dual purpose: not only to provide exceptional services that align with our clients' business needs, but also to empower them to seize fresh opportunities that offer a competitive advantage in today's fierce corporate landscape.</p>
                </div>
            </div>
        </div>
        </div>
        <div
          className={`col-md-6 col-lg-4 wow fadeInUp ${activeCategory !== 'education' ? 'd-none' : ''}`}
          data-wow-delay="0.5s"
        >
          <div class="service-item">
            <img class="img-fluid" Alt="LaterEntrty"src={img1} alt="LaterEntry"/>
            
            <div class="service-detail">
                <div class="service-title">
                    <hr style={{color:"#379cff"}} class="w-25"/>
                    <h3 style={{color:"#379cff"}} class="mb-0">LaterEntry</h3>
                    <hr style={{color:"#379cff"}} class="w-25"/>
                    <p style={{color:"white",float:"right",fontStyle:"oblique"}} class="mb-0"> Unlocking Your Second Chance</p>
                </div>
                <div class="service-text">
                    <p class="text-white mb-0 mt-5">LaterEntry is an online platform that offers guidance to people looking to restart their career at every step, from resume building to facing interviews to finding a job. Register to find opportunities from professionals who are willing to help you succeed with a second chance.</p>
                </div>
            </div>
        </div>
        </div>
        <div
          className={`col-md-6 col-lg-4 wow fadeInUp ${activeCategory !== 'Travel&Hospitality' ? 'd-none' : ''}`}
          data-wow-delay="0.5s"
        >
          <div class="service-item">
            <img Alt="Services"style={{borderRadius:"30px",objectFit:"cover",overflow:"hidden"}} class="img-fluid" src={img} alt="Anchorewellness"/>
           
            <div class="service-detail">
                <div class="service-title">
                    <hr style={{color:"#379cff"}}  class="w-25"/>
                    <h3 style={{color:"#379cff"}} class="mb-0">Anchor Wellness</h3>
                    <hr style={{color:"#379cff"}}   class="w-25"/>
                    <p style={{color:"white",float:"right",fontStyle:"oblique"}} class="mb-0">Revolutionary Healers</p>

                </div>
                <div class="service-text">
                    <p class="text-white mb-0 mt-5">Anchor Wellness Center revolutionizes healthcare with integrated, evidence-informed services, prioritizing continuity of care. Our expert team creates a transformative, holistic approach to optimize physical, mental, and nutritional health, embodying hope for our community.</p>
                </div>
            </div>
        </div>
        </div>
        <div
          className={`col-md-6 col-lg-4 wow fadeInUp ${activeCategory !== 'Retail&Ecommerce' ? 'd-none' : ''}`}
          data-wow-delay="0.5s"
        >
          <div class="service-item">
            <img class="img-fluid" Alt="Services" src={img2} alt="Signinsoft"/>
           
            <div class="service-detail">
                <div class="service-title">
                <hr style={{color:"#379cff"}}  class="w-25"/>
                    <h3 style={{color:"#379cff"}} class="mb-0">Signin Soft</h3>
                    <hr style={{color:"#379cff"}}  class="w-25"/>
                    <p style={{color:"white",float:"right",fontStyle:"oblique"}} class="mb-0">Get into your Future</p>

                </div>
                <div class="service-text">
                    <p class="text-white mb-0 mt-5">Signin Soft Inc. is founded with a dual purpose: not only to provide exceptional services that align with our clients' business needs, but also to empower them to seize fresh opportunities that offer a competitive advantage in today's fierce corporate landscape.</p>
                </div>
            </div>
        </div>
        </div>
      </div>
    </div>

<div>

</div>


   
</div>

</div>
</div>


  

  );
};

export default Portfoliohome;
