import React ,{useEffect,useState,useRef}from 'react';
import img from '../../src/assets/images/favicon.png'
import img1 from '../../src/assets/images/KH-LOGO.png'
import About from './about';
import Service from './service';
import Portfoliohome from './portfoliohome';
import Projectshome from './projectshome';
import ContactUs from './ContactUs';
// import Professional from './professional';
import Footer from '../footer';
import Header from '../header';
import Section from './section';
import Feedback from './feedback';
import ChatBot from 'react-simple-chatbot';
import { ThemeProvider } from 'styled-components';


const theme = {
  background: '#ffff',
  headerBgColor: '#379CFF',
  headerFontSize: '20px',
  botBubbleColor: '#0F3789',
  headerFontColor: 'white',
  botFontColor: 'white',
  userBubbleColor: '#379CFF',
  userFontColor: 'white',
};

const config = {
  botAvatar: img,
  floating: true,
  };
const Home = () => {
  
  const steps = [
    {
      id: "Greet",
      message: "Hello, Welcome to our KnackHook Website",
      trigger: "GetEmail",
      role: "system"
    },
    {
      id: "GetEmail",
      message: "Please enter your email address.",
      trigger: "SaveEmail",
      role: "system"
    },
    {
      id: "SaveEmail",
      user: true,
      trigger: "GetName",
      role: "user",
      validator: (value) => {
        if (/^\S+@\S+\.\S+$/.test(value)) {
          setUserInfo(prevState => ({ ...prevState, email: value })); 
          return true;
        } else {
          return "Please enter a valid email address.";
        }
      },
    },
    {
      id: "GetName",
      message: "Please enter your name.",
      trigger: "SaveName",
      role: "system"
    },
    {
      id: "SaveName",
      user: true,
      trigger: "Name",
      role: "user",
      validator: (value) => {
        if (value.trim()) {
          setUserInfo(prevState => ({ ...prevState, name: value })); 
          return true;
        } else {
          return "Please enter your name.";
        }
      },
    },
    {
      id: "Name",
      message: "We offer a range of services, projects, and portfolios to cater to your needs. How can we assist you today?",
      trigger: "ChooseServicesProjectsPortfolios",
      role: "system"
    },
    {
      id: "ChooseServicesProjectsPortfolios",
      options: [
        { value: "Services", label: "Services", trigger: "Services" },
        { value: "Projects", label: "Projects", trigger: "Projects" },
        { value: "Portfolios", label: "Portfolios", trigger: "Portfolios" },
      ],
    },
    {
      id: "Portfolios",
      message: "We offer the following types of Portfolios:",
      trigger: "ChoosePortfolios",
      role: "system"
    },
    {
      id: "ChoosePortfolios",
      options: [
        { value: "Finance", label: "Finance", trigger: "FinanceDetails" },
        { value: "Education", label: "Education", trigger: "EducationDetails" },
        { value: "Travel and Hospitality", label: "Travel and Hospitality", trigger: "TravelDetails" },
        { value: "Retail and Ecommerce", label: "Retail and Ecommerce", trigger: "RetailDetails" }
      ],
    },
    {
      id: "FinanceDetails",
      message: "Our finance portfolio showcases expertise in financial solutions and strategies tailored to your business needs. How can we assist you further in this domain?",
      trigger: "FinanceHelp",
      role: "system"
    },
    {
      id: "FinanceHelp",
      user: true,
      trigger: "HandleProject",
      role: "user"
    },
    {
      id: "EducationDetails",
      message: "Within the education sector, we specialize in delivering innovative solutions to enrich the learning experiences for both students and educators. How can we further support you in this field?",
      trigger: "EducationHelp",
      role: "system"
    },
    {
      id: "EducationHelp",
      user: true,
      trigger: "HandleProject",
      role: "user"
    },
    
   
    {
      id: "TravelDetails",
      message: "Our travel and hospitality portfolio is centered around employing advanced technologies to streamline operations and elevate customer experiences within the industry. How may we be of assistance in this domain?",
      trigger: "TravelHelp",
      role: "system"
    },
    {
      id: "TravelHelp",
      user: true,
      trigger: "HandleProject",
      role: "user"
    },
    
    {
      id: "RetailDetails",
      message: "Within the realm of retail and ecommerce, we provide state-of-the-art solutions aimed at creating seamless shopping experiences and driving business growth. How can we assist you further in this area?",
      trigger: "RetailHelp",
      role: "system"
    },
    {
      id: "RetailHelp",
      user: true,
      trigger: "HandleProject",
      role: "user"
    },
    
    
    {
      id: "Projects",
      message: "We have completed projects in various technologies. Could you please specify the technology you are interested in?",
      trigger: "UserInputProjects",
      role: "system"
    },
   
    {
      id: "UserInputProjects",
      user: true,
      trigger: "TechnologyResponse",
      role: "user"
    },
    {
      id: "TechnologyResponse",
      message: "We have highly skilled developers specializing in {previousValue}. Executing projects in this technology is well within our capabilities!",
      trigger: "userproject",
      role: "system"
    },
    {
      id: "userproject",
      user: true,
      trigger: "HandleProject",
      role: "user"
    },
    {
      id: "HandleProject",
      message: "Thank you for reaching out. Our team will contact you as soon as possible. Could you please provide your contact details?",
      trigger: "HandleContact",
      role: "system"
    },
   
    {
      id: "End",
      message: "Thank you for using our KnackHook service!",
      end: true,
    },
    {
      id: "Services",
      message: "We offer the following types of services",
      trigger: "ChooseService",
      role: "system"
    },
    {
      id: "ChooseService",
      options: [
        { value: "Cloud Services", label: "Cloud Services", trigger: "CloudServices" },
        { value: "Enterprise Solution", label: "Enterprise Solution", trigger: "EnterpriseSolution" },
        { value: "Data and Analytics", label: "Data and Analytics", trigger: "DataAndAnalytics" },
        { value: "Consulting", label: "Consulting", trigger: "Consulting" },
        { value: "Application Development", label: "Application Development", trigger: "ApplicationDevelopment" },
        { value: "Artificial Intelligence (AI)", label: "Artificial Intelligence (AI)", trigger: "AI" },
      ],
    },
    {
      id: "CloudServices",
      message: "Cloud Services: Scalable storage, computing power, and secure data management.",
      trigger: "UserInput",
      role: "system"
    },
    {
      id: "EnterpriseSolution",
      message: "Enterprise Solution: Tailored solutions for business growth and efficiency.",
      trigger: "UserInput",
      role: "system"
    },
    {
      id: "DataAndAnalytics",
      message: "Data and Analytics: Turning data into actionable insights for informed decisions.",
      trigger: "UserInput",
      role: "system"
    },
    {
      id: "Consulting",
      message: "Consulting: Expert guidance for strategic business decisions and innovation.",
      trigger: "UserInput",
      role: "system"
    },
    {
      id: "ApplicationDevelopment",
      message: "Application Development: Creating customized applications to meet your unique needs.",
      trigger: "UserInput",
      role: "system"
    },
    {
      id: "AI",
      message: "Artificial Intelligence (AI): Harnessing the power of AI for intelligent solutions.",
      trigger: "UserInput",
      role: "system"
    },    
    
    
    
    
    {
      id: "UserInput",
      user: true,
      trigger: "Contact",
      role: "user"
    },
    {
      id: "Contact",
      message: "Thank you for reaching out. Our team will contact you as soon as possible. Could you please provide your contact details?",
      trigger: "HandleContact",
      role: "system"
    },
    
    {
      id: "HandleContact",
      user: true,
      trigger: "HandleText",
      role: "user",
      validator: (value) => {
        if (value.trim()) {
          setUserInfo(prevState => ({ ...prevState, phoneNumber: value })); 
          return true;
        } else {
          return "Please enter your phoneNumber.";
        }
      },
    },
    {
      id: "HandlePhone",
      message: "Thank you for providing your phone number. Our team will be in touch with you via phone call.",
      trigger: "End",
      role: "system"
    },
    {
      id: "HandleText",
      message: "Thank you for providing your information. Our team will be in touch with you via email or Phone.",
      trigger: "End",
      role: "system"
    },
    
    
    {
      id: "TempStep",
      message: "Temporary step to check if triggered",
      trigger: "SaveConversation",
    },
    {
      id: "SaveConversation",
      message: "Collecting conversation data...",
      trigger: "End",
      validator: (value) => {
        const conversationData = JSON.stringify(value);
        console.log('conversationData:', conversationData); 
        setConversation(conversationData);
        return true;
      },
    },
    
    
    
   
  ];

 
  const [userInfo, setUserInfo] = useState({ name: '', email: '', phoneNumber: '' });
  const [conversation, setConversation] = useState([]);
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);
  const [chatbotKey, setChatbotKey] = useState(0);
  const [refreshChatbot, setRefreshChatbot] = useState(false);

 
 
  const toggleChatbot = () => {
    handleChatEnd(steps); // Send conversation data before closing the chatbot
    setTimeout(() => {
      setIsChatbotOpen((prevIsChatbotOpen) => !prevIsChatbotOpen);
      if (isChatbotOpen) {
        setChatbotKey((prevKey) => prevKey + 1);
      }
    }, 100); 
  };  
  
  const customHeader = (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between', 
        alignItems: 'center',
        backgroundColor: '#379CFF', 
        padding: '10px',
      }}
    >
      <h2 style={{ margin: 0,fontSize:"20px" ,color: 'white' }}>KnackHook</h2>
      <button onClick={toggleChatbot} style={{ background: 'none', border: 'none', color: 'white', cursor: 'pointer' }}>
        X
      </button>
    </div>
  );
  const handleChatEnd = (steps) => {
    const name =userInfo.name;
    const email =userInfo.email;
    
    const phoneNumber =userInfo.phoneNumber;

     setUserInfo({ name, email, phoneNumber });
console.log('steps',steps)
  const messages = steps?.renderedSteps?.map(step => step.message).join('<br>');
  const data = {
    name,
    email,
    phoneNumber,
    conversation: messages, 
  };

    fetch('https://assessnhireapi.azurewebsites.net/api/KnackHookChatBox/AddKnackHookChat', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    .then(response => response.json())
    .then(data => {
      console.log('Success:', data);
     // refreshChatbot();
     //setChatbotKey((prevKey) => prevKey + 1);
    })
    .catch(error => {
      console.error('Error:', error);
      
    });
   // 
  };

 
  useEffect(() => {
    const scrollToSection = () => {
      const hash = window.location.hash;

      if (hash) {
        const targetSection = document.querySelector(hash);

        if (targetSection) {
          targetSection.scrollIntoView({ behavior: 'smooth' });
        }
      }
    };

    scrollToSection();

    window.addEventListener('load', scrollToSection);

    return () => {
      window.removeEventListener('load', scrollToSection);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
 
  return (
    <div>
      <Header/>
      <div id="banner"> <Section/></div>
      <div id="About" style={{marginTop:"100px"}}><About/></div>
      <div id="Services" style={{marginTop:"100px"}}><Service/></div>
      <div id="Portfolio" style={{marginTop:"100px"}}><Portfoliohome/></div>
      <div  id="Projectshome"  style={{marginTop:"100px"}}><Projectshome/></div>
      {/* <div style={{marginTop:"100px"}}><Professional/></div> */}
      <div style={{marginTop:"100px"}}><Feedback/></div>
      {/*<div id="Contact" style={{marginTop:"100px"}}><ContactUs/></div>*/}
      <a href="/#banner"><div id="banner" style={{float:"right",marginRight:"5px"}}>
   <button class="scroll" style={{backgroundColor: "#379CFF",height: "60px",width:"60px",borderRadius:"50%"}}><i class="fa fa-arrow-up"></i></button>
   </div></a>
   <div>
          
             <div style={{ flex: '1', display: 'flex', justifyContent: 'flex-end' }}>
             <ThemeProvider theme={theme}>
        <ChatBot
         key={chatbotKey}
          opened={isChatbotOpen}
          headerComponent={customHeader} 
          steps={steps}
          botAvatar={img}
          handleEnd={handleChatEnd}
          floating={true}
          
        />
      </ThemeProvider>
      </div>
           
         
        </div>
      <div style={{marginTop:"100px"}}><Footer/></div>
      </div>
  );
};

export default Home;





