import React from 'react'
import image from '../src/assets/images/KH-LOGO.png'
import { Link } from 'react-router-dom';



const Footer = () => {
   const currentYear = new Date().getFullYear();
  return (
   <div>
   <footer class="p-3 mt-5">
   <div class="container">
   <div class="row">
      <div class="col-lg-2 col-xl-2 col-md-12 col-sm-12">
      <a href="/#home"><img src={image} class="img-fluid" alt="logo"/></a>
            {/* <h5 style={{color:"#379CFF",fontSize: "25px"}}>KNACKHOOK</h5> */}
      </div>
      <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12">
            <ul class="footer-list" >
            <li ><a class="list-f" href="/Home">Home</a></li>
            <li><a  class="list-f" href="/AboutUs" >About Us</a></li>
            <li > <a class="list-f" href="/CloudServices" >Services</a></li>
            <li > <a class="list-f" href="/Portfolio" >Portfolio</a></li>
            <li ><a class="list-f" href="/Projects" >Projects</a></li>
            <li > <a class="list-f"  href="/ContactUs" >Contact Us</a></li>
            </ul>
      </div>
      <div class="col-lg-4 col-xl-4 col-md-4 col-sm-12">
               <ul class="social-links">
                  <li class="list-f"><a href="https://www.facebook.com/knackhook/" target="_blank"><i class="fa fa-facebook-f social"></i></a></li>
                  <li class="list-f"><a href="https://twitter.com/Knack_Hook" target="_blank"><i class="fa fa-twitter social"></i></a></li>
                  <li class="list-f"><a href="https://www.linkedin.com/company/knackhook/mycompany/" target="_blank"><i class="fa fa-linkedin social"></i></a></li>
               </ul>
      </div>

   </div>
   <hr/>
   <div class="row">
      <div class="col-sm-12 col-lg-6 col-xl-6 col-md-6">
         <p class="Copyright">Copyright © {currentYear} KnackHook. All Rights Reserved.</p>
      </div>
      <div class="col-sm-12 col-lg-6 col-xl-6 col-md-6">
         <ul class="footer-list2" >
            {/* <Link to="/Privacy&Policy"><li class="list-f2">Privacy & Policy</li></Link> */}
           <li class="list-f2"><a  href="/Privacy&Policy" >Privacy & Policy</a></li>
            <li class="list-f2">FAQ's</li>
            <li class="list-f2">Terms & Conditions</li>
            
         </ul>
      </div>
   </div>
</div>
</footer>
   </div>
   
  )
}

export default Footer