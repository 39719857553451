import React from 'react';
import Header from '../header';
import Footer from '../footer';
import khconsult from '../../src/assets/images/consult.jpg'
const Consulting = () => {
return (
<div>
   <Header/>
   <img src={khconsult} alt="About Knackhook" style={{width:'100%',objectFit:'cover',height:'350px'}} class="consult-img"/>
   <div className="container mt-5 mb-5">
      <h3 style={{color:"#379CFFFC"}}>Consulting</h3>
      <p className='mt-3' style={{color:"#fff"}}>"KnackHook offers specialized consulting services to help businesses address challenges, make strategic decisions, and achieve sustainable growth. Our experienced team is dedicated to your success."</p>
      <p className='mt-3' style={{color:"#fff"}}> Our consulting services provide strategic advisory to help businesses navigate complex challenges, identify opportunities, and achieve their goals effectively.</p>
      <div className="card p-5 mt-5" style={{borderRadius:'30px',border:'1px solid grey',background:'none'}}>
      <div className="row">
         <div className="col-sm-12 col-lg-4 col-xl-4 col-md-4">
            <div class="card p-3" 
            style={{
            backgroundColor: "transparent",
            backgroundImage: "radial-gradient(at center top, rgba(55, 156, 255, 0.4) 0%, rgb(3, 5, 16) 88%)",
            borderStyle: "solid",
            borderWidth:"1",
            borderColor: "rgba(55, 156, 255, 0.99)",
            borderRadius: "30px",
            boxShadow: "rgba(55, 156, 255, 0.99) 0px 0px 10px 0px"
            }}>
            <p className='mt-3' style={{color:"#fff"}}>"At KnackHook, our consulting expertise spans various industries and domains, allowing us to offer tailored solutions that meet your specific needs 
               and objectives. From strategic planning to implementation support, we're with you every step of the way."
            </p>
         </div>
      </div>
      <div className="col-sm-12 col-lg-8 col-xl-8 col-md-8">
         <div style={{display:'flex'}}>
            <div className='mt-2' style={{color: '#fff',
            borderStyle:'solid',
            borderWidth: '1px 1px 1px 1px',
            borderColor: '#379CFFFC',
            borderRadius: '50%',
            height:'40px',
            width:'40px',
            boxShadow: '0px 0px 6px 0px rgba(54.99999999999997, 156.00000000000023, 255, 0.9882352941176471)',
            margin: '0px 8px 0px 0px',
            padding: '9px 9px 9px 9px'}}>
            <i class="fa fa-check" aria-hidden="true" style={{color: '#fff',marginLeft:'5px'}}></i>
         </div>
         <div className='mt-2'>
            <h1 Alt="Business Strategy Consulting"style={{color:"#379CFFFC",fontSize:'20px'}}>Business Strategy Consulting:</h1>
            <p>Our team offers business strategy consulting to develop tailored strategies that align with your organization's objectives, market dynamics, and competitive landscape.</p>
         </div>
      </div>
     
      <div style={{display:'flex'}}>
            <div className='mt-2'style={{color: '#fff',
            borderStyle:'solid',
            borderWidth: '1px 1px 1px 1px',
            borderColor: '#379CFFFC',
            borderRadius: '50%',
            height:'40px',
            width:'40px',
            boxShadow: '0px 0px 6px 0px rgba(54.99999999999997, 156.00000000000023, 255, 0.9882352941176471)',
            margin: '0px 8px 0px 0px',
            padding: '9px 9px 9px 9px'}}>
            <i class="fa fa-check" aria-hidden="true" style={{color: '#fff',marginLeft:'5px'}}></i>
         </div>
      
      <div className="mt-2">
         <h5 style={{color:"#379CFFFC"}}>Operational Excellence Consulting:</h5>
         <p>We provide operational excellence consulting to identify inefficiencies, optimize processes, and enhance performance, driving operational excellence and cost savings.</p>
      </div>
      </div>
 
</div>
</div>
<div className="row mt-3">
   <div className="col-sm-12 col-lg-8 col-xl-8 col-md-8">
    
      <div style={{display:'flex'}}  className='mt-5'>
         
            <div className='mt-2'style={{color: '#fff',

            borderStyle:'solid',
            borderWidth: '1px 1px 1px 1px',
            borderColor: '#379CFFFC',
            borderRadius: '50%',
            height:'40px',
            width:'40px',
            boxShadow: '0px 0px 6px 0px rgba(54.99999999999997, 156.00000000000023, 255, 0.9882352941176471)',
            margin: '0px 8px 0px 0px',
            padding: '9px 9px 9px 9px'}}>
            <i class="fa fa-check" aria-hidden="true" style={{color: '#fff',marginLeft:'5px'}}></i>
         </div>
     
      <div className="mt-2">
         <h5 style={{color:"#379CFFFC"}}>Organizational Development Consulting:</h5>
         <p>Our consultants offer organizational development consulting to support leadership development, talent management, and cultural transformation initiatives, fostering a high-performance culture and employee engagement.</p>
      </div>
   </div>
   <div className="row">
   <div className='mt-2' style={{display:'flex'}}>
      
         <div className='mt-2' style={{color: '#fff',
         borderStyle:'solid',
         borderWidth: '1px 1px 1px 1px',
         borderColor: '#379CFFFC',
         borderRadius: '50%',
         height:'40px',
         width:'40px',
         boxShadow: '0px 0px 6px 0px rgba(54.99999999999997, 156.00000000000023, 255, 0.9882352941176471)',
         margin: '0px 8px 0px 0px',
         padding: '9px 9px 9px 9px'}}>
         <i class="fa fa-check" aria-hidden="true" style={{color: '#fff',marginLeft:'5px'}}></i>
      </div>
   
   <div className="mt-2">
      <h5 style={{color:"#379CFFFC"}}>Digital Transformation Consulting:</h5>
      <p>We specialize in digital transformation consulting to help businesses leverage emerging technologies, digitize processes, and innovate their business models to stay ahead in the digital age.</p>
   </div>
</div>
</div>
</div>
<div className="col-sm-12 col-lg-4 col-xl-4 col-md-4">
   <div className="mt-5" class="card p-3" 
   style={{
    
   backgroundColor: "transparent",
   backgroundImage: "radial-gradient(at center top, rgba(55, 156, 255, 0.4) 0%, rgb(3, 5, 16) 88%)",
   borderStyle: "solid",
   borderWidth:"1",
   borderColor: "rgba(55, 156, 255, 0.99)",
   borderRadius: "30px",
   boxShadow: "rgba(55, 156, 255, 0.99) 0px 0px 10px 0px"
   }}>
   <p className='mt-3' style={{color:"#fff"}}>"Partner with KnackHook for personalized consulting services that prioritize your organization's success. Our collaborative approach ensures
      that we fully understand your challenges and opportunities, enabling us to provide actionable insights and recommendations."
   </p>
</div>
</div>
</div>
</div>
</div>
<Footer/>
</div>
);
};
export default Consulting;