import React from 'react'
import image from '../../src/assets/images/contact (2.jpg'
import image1 from '../../src/assets/images/contact1.png'


const Feedback = () => {
  
  return (


  <div class="container mt-5">
   <div class="row">
        <div class="col-1">
           <div class="head-a">
              <div class="head-b" >
                <h5 class="elementor-heading-title elementor-size-default" style={{color: "#379CFFFC"}}>TESTIMONIALS</h5>
              </div>
           </div>
        </div>
       <div class="col-11">
           <div>
             <div class="head2a"style={{textAlign:"left"}}>
                <h2 class="head2b" >
                    <span class="dynamic-wrapper style-gradient">
                    <span class="dynamic-text" >Real Feedback From Our Clients</span></span>
                    <span class="normal-text style-gradient" ></span>
                 </h2>
             </div>
           </div>
        </div>
     </div>
   <div class="card mt-5" style={{
   borderRadius: '20px',
  border: '1px solid gray',
  backgroundColor: '#090C1F'
 }}>
   <div class="row">

      <div class="col-sm-12 col-xl-4 col-lg-4 col-md-4 p-5">
         <div class="card " style={{ background: 'none' }}>
         <img src={image} alt="feedback" style={{ 
  width: '100%',
  height: '320px',
  objectFit: 'cover',
  display: 'block',
  marginTop: '-100px',
   borderStyle: 'solid',
   borderWidth: '1px',
      borderColor: '#379CFF',
  boxShadow: '0px 0px 16px 0px #379CFF',
  borderRadius: '20px',
   position: 'relative',
   overflow: 'hidden',
  display: 'inline-block'
 }}/>
         </div>
      </div>
      <div class="col-sm-12 col-xl-8 col-lg-8 col-md-8 p-5">
           

      <div id="carouselExampleFade" class="carousel slide carousel-fade" data-bs-ride="carousel">
         <div class="carousel-inner">
         
           <div class="carousel-item active">
    <h6 class="" style={{ fontSize: '15px' }}>KnackHook has masterfully crafted this website, revolutionizing healthcare with integrated, evidence-informed services. Prioritizing continuity of care, their expert team embodies hope for our community by optimizing physical, mental, and nutritional health through a transformative, holistic approach.We extend our heartfelt gratitude for KnackHook's invaluable contributions to our success.</h6>
    <div class="">
        <i class="fa fa-star-o" style={{ color: 'orange' }}></i><i class="fa fa-star-o" style={{ color: 'orange' }}></i><i class="fa fa-star-o" style={{ color: 'orange' }}></i><i class="fa fa-star-o" style={{ color: 'orange' }}></i><i class="fa fa-star-o"style={{ color: 'orange' }}></i>
        <h6 class="text-white mt-3" style={{ fontSize: '20px' }}>David</h6>
        <p>Anchor Wellness</p>
    </div>
</div>
<div class="carousel-item">
    <h6 class="" style={{ fontSize: '15px' }}>KnackHook has played a crucial role at Signin Soft Inc., providing exceptional software development and training services. Their expertise and commitment have consistently exceeded our expectations. With their support, we've seized opportunities and gained a competitive edge in the corporate landscape.We're highly impressed with KnackHook's contributions to our success.</h6>
    <div class="">
        <i class="fa fa-star-o" style={{ color: 'orange' }}></i><i class="fa fa-star-o" style={{ color: 'orange' }}></i><i class="fa fa-star-o" style={{ color: 'orange' }}></i><i class="fa fa-star-o" style={{ color: 'orange' }}></i><i class="fa fa-star-o" style={{ color: 'orange' }}></i>
        <h6 class="text-white mt-3" style={{ fontSize: '20px' }}>Surendra</h6>
        <p>Signin Soft</p>
    </div>
</div>
           {/* <div class="carousel-item">
            <h6 class="" style={{ fontSize: '15px' }}  >sualization solution provided by Virtty gave us a competitive edge. It allowed our customers to visualize our products in a realistic setting, leading to increased sales and customer satisfaction.</h6>
         
            <div class="">
            <i class="fa fa-star-o" style={{ color: 'orange' }}></i><i class="fa fa-star-o" style={{ color: 'orange' }}></i><i class="fa fa-star-o" style={{ color: 'orange' }}></i><i class="fa fa-star-o" style={{ color: 'orange' }}></i><i class="fa fa-star-o" style={{ color: 'orange' }}></i>
            <h6 class="text-white" style={{ fontSize: '15px' }}  >James</h6>
            <p>HR</p> 
         </div> 
           </div> */}
         </div>
         <button class="carousel-control-prev feedback"    type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
           <span class="carousel-control-prev-icon" aria-hidden="true"></span>
           <span class="visually-hidden">Previous</span>
         </button>
         <button class="carousel-control-next feedback"   type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
           <span class="carousel-control-next-icon" aria-hidden="true"></span>
           <span class="visually-hidden">Next</span>
         </button>
       </div>


      </div>
   </div>
</div>
<div id="Contact" class="container mt-5">
      <div class="head2a"style={{ textAlign: 'center' }}>
         <h2 class="head2b" >
            <span class="dynamic-wrapper style-gradient">
            <span class="dynamic-text" >Contact Us
            </span></span>
            <span class="normal-text style-gradient" ></span>
         </h2>
      </div>
      <div class="row mt-5">
         <div class="col-sm-12 col-md-9 col-xl-9 col-lg-9">
            <div class="accordion accordion-flush mt-5" id="accordionFlushExample" >
               <div class="accordion-item" >
                 <h2 class="accordion-header" id="flush-headingOne">
                   <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                   US
                   </button>
                 </h2>
                 <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                   <div class="accordion-body">
                     <div class="row">
                        <div class="col-sm-12 col-lg-4 col-xl-4 col-md-4">
                           <div class="">
                              {/* <h6 style={{ fontSize: '15px' }}>Address</h6> */}
                              <p style={{ color: 'white' }}><i style={{color: '#379CFF',marginRight:"10px" }}class="fa fa-map-marker"></i>KNACKHOOK LLC
<br/>1420 156TH AVE NE SUITE F,
<br/>BELLEVUE, WA 98007-4421.</p>
                                
                            <p  style={{ color: 'white' }}> <i style={{color: '#379CFF',marginRight:"10px" }} class="fa fa-envelope"></i>info@knackhook.com</p>
                             <p style={{ color: 'white' }}> <i style={{color: '#379CFF',marginRight:"10px" }}class="fa fa-phone"></i>+1 (425) 548-6321</p>
                              </div>
                        </div>
                        
                     </div>
                   </div>
                 </div>
               </div>
               
             </div>
   
   
             <div class="accordion accordion-flush mt-4" id="accordionFlushExample" >
               <div class="accordion-item" >
                 <h2 class="accordion-header" id="flush-headingtwo">
                   <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsetwo" aria-expanded="false" aria-controls="flush-collapsetwo">
                   India
                   </button>
                 </h2>
                 <div id="flush-collapsetwo" class="accordion-collapse collapse" aria-labelledby="flush-headingtwo" data-bs-parent="#accordionFlushExample">
                   <div class="accordion-body">
                     <div class="row">
                        <div class="col-sm-12 col-lg-4 col-xl-4 col-md-4">
                           <div class="">
                              {/* <h6 style={{ fontSize: '15px' }}>Address</h6> */}
                             <p style={{ color: 'white' }}> <i style={{color: '#379CFF',marginRight:"10px" }}class="fa fa-map-marker"></i>Plot No 92A, Flat No 301,
                                 Kondapur, Hyderabad-500084.</p>
                                <p style={{ color: 'white' }}><i style={{color: '#379CFF',marginRight:"10px" }} class="fa fa-envelope"></i>info@knackhook.com</p>
                                 <p  style={{ color: 'white' }}> <i style={{color: '#379CFF',marginRight:"10px" }}class="fa fa-phone"></i>+91 (868) 862-3071</p>
                           </div>
                        </div>
                     </div>
                   </div>
                 </div>
               </div>
               
             </div>
   
   
             {/* <div class="accordion accordion-flush mt-4" id="accordionFlushExample" >
               <div class="accordion-item" >
                 <h2 class="accordion-header" id="flush-headingthree">
                   <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsethree" aria-expanded="false" aria-controls="flush-collapsethree">
                     UK
                   </button>
                 </h2>
                 <div id="flush-collapsethree" class="accordion-collapse collapse" aria-labelledby="flush-headingthree" data-bs-parent="#accordionFlushExample">
                   <div class="accordion-body">
                     <div class="row">
                        <div class="col-sm-12 col-lg-4 col-xl-4 col-md-4">
                           <div class="">
                           <p style={{ color: 'white' }}><i style={{color: '#379CFF',marginRight:"10px" }}class="fa fa-map-marker"></i>18666 Redmond Way,
                             Redmond, WA, 98052.</p>
                                
                            <p  style={{ color: 'white' }}> <i style={{color: '#379CFF',marginRight:"10px" }} class="fa fa-envelope"></i>info@knackhook.com</p>
                             <p style={{ color: 'white' }}> <i style={{color: '#379CFF',marginRight:"10px" }}class="fa fa-phone"></i>+1 425-540-7888</p>
                           
                           </div>
                        </div>
                     </div>
                   </div>
                 </div>
               </div>
               
             </div> */}
         </div>
         <div class="col-sm-12 col-md-12 col-xl-3 col-lg-3 text-center" >
            <img src={image1}  class="contactimage"alt="contact" />
         </div>
      </div>
     
   </div>
  </div>
  
  )
}

export default Feedback