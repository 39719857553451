import React from 'react';
import Header from '../header';
import Footer from '../footer';
import le from '../../src/assets/images/p-laterentry.png';

import Khians from '../../src/assets/images/1.png';
import AssessNHire from '../../src/assets/images/assessnhire.png';
import anchor from '../../src/assets/images/anchor.jpg';
import sqft from '../../src/assets/images/sqft.png';
const Projects = () => {
    return (
<div>
    <Header/>
    <section  id="banner" style={{ 
      backgroundColor: 'transparent',
      backgroundImage: 'radial-gradient(at bottom right, #3758FF7D 0%, #030510 64%)'
    }}>
   <div class="container pt-5">
      <div class="elementor-background-overlay" style={{
  backgroundImage: 'url(https://kit.baliniz.com/virtty/wp-content/uploads/sites/55/2023/05/noise.png)',
  backgroundPosition: 'center center',
  backgroundRepeat: 'repeat',
  backgroundSize: '14% auto',
  opacity: 0.5,
  transition: 'background 0.3s, border-radius 0.3s, opacity 0.3s'
}}></div>
    
         
   
         <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
               <div class="text-center">
               <h2 class="head3b">
            <span class="dynamic-wrapper style-gradient">
            <span class="dynamic-text" >Projects</span> 
         </span>
         <p style={{fontSize:"17px"}} class="mt-3">"KnackHook adeptly delivers custom digital marketing, web development, and design projects, prioritizing client goals. Their innovative solutions and commitment to satisfaction ensure effective outcomes, tailored to individual business needs."</p>
         <span class="normal-text style-gradient" ></span>
         </h2>
               </div>
            </div>
           
         
         </div>
   </div>
   </section>
   <div class="container">
   <div class="row mt-5 mb-5">
                    <div class="col-sm-12 col-xl-8 col-lg-8 col-md-8">
                    <h3 className='mt-4' style={{color: '#379CFFFC'}}>Squar Ft</h3>
                        <p className='mt-4'>SquarFt is a real-estate website designed for properties to Sell/Buy/Share/Rent. It also has
Service Provider options like Packers & Movers, Plumbing, and Electrical work where users
can book the service and avail discounts on various services.
                        </p>
                    </div>
                    <div class="col-sm-12 col-xl-4 col-lg-4 col-md-4">
                    <img src={sqft} alt="About Knackhook" style={{borderRadius:'30px'}} class="about-images2 img-fluid"/>
                    </div>
                </div>
                <div class="row mt-5 mb-5">
                    
                    <div class="col-sm-12 col-xl-4 col-lg-4 col-md-4">
                    <img src={anchor} alt="About Knackhook" style={{borderRadius:'30px'}}class="about-images4 img-fluid"/>
                    </div>
                    <div class="col-sm-12 col-xl-8 col-lg-8 col-md-8">
                    <h3 className='mt-4' style={{color: '#379CFFFC'}}>Anchor Wellness</h3>
                        <p className='mt-4'>
                        Anchor Wellness is a rich look and feel web platform designed for Instructors and members
where both can interact with each other. It offers social networking with in the platform
where member can connect with Instructor, vice-versa and can see the real-time Program and
Blog updates.

                        </p>
                    </div>
                </div>
                <div class="row mt-5 mb-5">
                    <div class="col-sm-12 col-xl-8 col-lg-8 col-md-8">
                    <h3 className='mt-4' style={{color: '#379CFFFC'}}>LaterEntry</h3>
                        <p className='mt-4'>LaterEntry is an online platform that offers guidance to people looking to restart their career
at every step, from resume building to facing interviews to finding a job. register to find
opportunities from professionals who are willing to help you succeed with a second chance.
                        </p>
                    </div>
                    <div class="col-sm-12 col-xl-4 col-lg-4 col-md-4">
                    <img src={le} alt="About Knackhook" style={{borderRadius:'30px'}} class="about-images2 img-fluid"/>
                    </div>
                </div>
                <div class="row mt-5 mb-5">
                    
                    <div class="col-sm-12 col-xl-4 col-lg-4 col-md-4">
                    <img src={Khians} alt="About Knackhook" style={{borderRadius:'30px'}}class="about-images4 img-fluid"/>
                    </div>
                    <div class="col-sm-12 col-xl-8 col-lg-8 col-md-8">
                    <h3 className='mt-4' style={{color: '#379CFFFC'}}>KhiansHub</h3>
                        <p className='mt-4'>Khians Hub is an intranet landing page is the first page employees see on the intranet. It’s the essential hub for workplace information that highlights company news, updates, important documents, links to other organizational pages, work groups and applications that allows employees to find what they need.
                        </p>
                    </div>
                </div>
                <div class="row mt-5 mb-5">
                    <div class="col-sm-12 col-xl-8 col-lg-8 col-md-8">
                    <h3 className='mt-4' style={{color: '#379CFFFC'}}>AssessNHire</h3>
                        <p className='mt-4'>AssessNHire is a comprehensive web-based platform designed for hiring processes. It serves as a bridge between consultants and employers, facilitating the recruitment process by allowing consultants to register and input candidate details. These candidate profiles are then accessible to potential employers, who can efficiently select suitable candidates based on their specific job requirements. AssessNHire streamlines the hiring process, enabling seamless connections between consultants and employers while ensuring effective candidate selection for job postings.
                        </p>
                    </div>
                    <div class="col-sm-12 col-xl-4 col-lg-4 col-md-4">
                    <img src={AssessNHire} alt="About Knackhook" style={{borderRadius:'30px'}} class="about-images2 img-fluid"/>
                    </div>
                </div>
                       
        </div>
    <Footer/>
</div>
        );
};

export default Projects;