import React, { useState ,useEffect} from 'react';
import img from '../../src/assets/images/anchorr1 (1).jpg'
import img1 from '../../src/assets/images/later (1).jpg'
import img2 from '../../src/assets/images/signinsoft (1).jpg'
import img3 from '../../src/assets/images/khains (1).jpg'

import { Link } from 'react-router-dom';
import '../card.css'




 
  



const Projectshome = () => {
  return (
    <div className="container mt-5" id="Projects">
		 <div class="row">
      <div class="col-1">
         <div class="head-a">
            <div class="head-b" >
               <h5 class="elementor-heading-title elementor-size-default" style={{color: "#379CFFFC"}}>PROJECTS</h5>
            </div>
         </div>
      </div>
      <div class="col-11">
         <div>
            <div class="head2a"style={{textAlign:"left"}}>
               <h2 class="head2b" >
                  <span class="dynamic-wrapper style-gradient">
                  <span class="dynamic-text" >Latest Projects</span></span>
                  <span class="normal-text style-gradient" ></span>
               </h2>
            </div>
         </div>
      </div>
   </div>
    
        <div className="row ">
        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6" >
	<div class="wrap animate pop">
		<div class="overlay">
			<div class="overlay-content animate slide-left delay-2">
				<h1 class="animate slide-left pop delay-4" alt="Signinsoft">Squar Ft</h1>
			</div>
			<div class="image-content2 animate slide delay-5"></div>
					<div class="dots animate">
						<div class="dot animate slide-up delay-6"></div>
						<div class="dot animate slide-up delay-7"></div>
						<div class="dot animate slide-up delay-8"></div>
					</div>
		</div>
			<div class="text">
				<p><img class="inset" src={img2} alt="Signinsoft" />
        <h4><a className="text-decoration-none title">Driving Business Growth through Innovation</a></h4>

    <p class="mt-3" style={{color: "gray"}}>
    SquarFt is a real-estate website designed for properties to Sell/Buy/Share/Rent. It also has
Service Provider options like Packers & Movers, Plumbing, and Electrical work where users
can book the service and avail discounts on various services.
    </p>

    <div style={{borderLeft: "3px solid gray"}}>
        <p style={{marginLeft:"10px",color: "#379CFF"}}>
            Our team is driven by a shared passion for innovation and excellence. We thrive on leveraging our expertise to provide tailored solutions that drive real results for our clients.
        </p>
    </div>

    

    <p class="mt-3" style={{color: "gray"}}>
    Signin Soft Inc. is more than just a workplace; it's a realm of passion and dedication. With a deep well of experience and a mastery of specialized skills, our team is adept at providing state-of-the-art technology solutions tailored to a range of industries. From refining operational efficiency to streamlining critical processes, our mission is singular: to empower businesses in achieving their objectives and unlocking the coveted returns on their investments    </p></p>
			</div>
	</div>
    </div>
        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6  ">
	<div class="wrap animate pop">
		<div class="overlay">
			<div class="overlay-content animate slide-left delay-2">
				<h1 class="animate slide-left pop delay-4" alt="Anchorewellness">Anchor Wellness</h1>
			</div>
			<div class="image-content1 animate slide delay-5"></div>
					<div class="dots animate">
						<div class="dot animate slide-up delay-6"></div>
						<div class="dot animate slide-up delay-7"></div>
						<div class="dot animate slide-up delay-8"></div>
					</div>
		</div>
			
      <div class="text">
      <p><img class="inset" src={img} alt="Anchorewellness" /> 
    <h4><a className="text-decoration-none title">We Challenge The Traditional Health Care Model.</a></h4>
    <p className="title1">
        The Anchor Wellness Center was founded to challenge the way health and wellness services are delivered. Today, most individuals are experiencing a massive lack of continuity of care with isolated providers creating a barrier to progress.
    </p>

    <div style={{borderLeft: "3px solid gray", paddingLeft: "10px"}}>
        <p style={{color: "gray"}}>
            We’re different! Our Cincinnati based health collaborative is grounded in evidence-informed, integrated care.
        </p>
    </div>

    <p style={{color: "#379CFF"}}>
        Anchor’s practitioners challenge the traditional health care model and possess unmatched passion, deeper experience, training and credentials compared to other providers. Our team of critical thinkers thrive in transforming the culture of their specialized fields but believe in the power of a tribe who speed healing time by creating a comprehensive approach.
    </p>

    <p className="title1">
        From our revolutionary wellness and prevention programs to treating the bumps encountered while traveling through life, at Anchor we are determined to find the root cause to create better physical, mental and nutritional health. Our goal is to create a customized, transformative experience that empowers our clients to optimize their health.
    </p>

    <p style={{color: "#379CFF"}}>
        The anchor is the international symbol of hope. We strive to provide just that for the Anchor community. Whether you are a provider who is ready to throw in the towel because the traditional model isn’t serving you or you are an individual who just can’t seem to improve your health. We can help.
    </p>
    </p>
</div>

	</div>
  
    </div>
    
    <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
	<div class="wrap animate pop">
		<div class="overlay">
			<div class="overlay-content animate slide-left delay-2">
				<h1 class="animate slide-left pop delay-4" alt="LaterEntry">LaterEntry</h1>
				{/* <p class="animate slide-left pop delay-5" style={{color: 'white' , marginBottom : '2.5rem'}}>Kingdom: <em>Plantae</em></p> */}
			</div>
			<div class="image-content3 animate slide delay-5"></div>
					<div class="dots animate">
						<div class="dot animate slide-up delay-6"></div>
						<div class="dot animate slide-up delay-7"></div>
						<div class="dot animate slide-up delay-8"></div>
					</div>
		</div>
			<div class="text">
				<p><img class="inset" src={img1} alt="LaterEntry" />
        <h4><a className="text-decoration-none title">Empowering Your Career Comeback with LaterEntry</a></h4>

 

<p class="mt-3" style={{color: "gray"}}>
LaterEntry is an online platform that offers guidance to people looking to restart their career at every step, from resume building to facing interviews to finding a job. Register to find opportunities from professionals who are willing to help you succeed with a second chance.
</p>

<div style={{borderLeft: "3px solid gray"}}>
<p style={{marginLeft:"10px",color: "gray"}}>
We specialize in providing cutting-edge solutions that empower individuals to make a successful career comeback. Our team of experts combines technical expertise with creative vision to deliver personalized strategies for your unique journey.
</p>
</div>

<p style={{color: "#379CFF"}}>
LaterEntry's online platform is a beacon of hope for those seeking to rekindle their professional journey. Offering a range of services from resume building to interview preparation, we stand as a steadfast guide in your career comeback. Our community of dedicated professionals is ready to extend a helping hand, providing opportunities and expertise to ensure your success in obtaining that second chance. Register today and embark on a transformative path towards a brighter future.</p>

<p class="mt-3" style={{color: "gray"}}>
LaterEntry utilizes state-of-the-art technologies to create a seamless and engaging experience for our users. From advanced algorithms to user-friendly interfaces, we leverage the latest innovations to drive your success.
</p></p>
			</div>
	</div>
    </div>

	<div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
	<div class="wrap animate pop">
		<div class="overlay">
			<div class="overlay-content animate slide-left delay-2">
				<h1 class="animate slide-left pop delay-4" alt="KhiansHub">KhiansHub</h1>
				{/* <p class="animate slide-left pop delay-5" style={{color: 'white' , marginBottom : '2.5rem'}}>Kingdom: <em>Plantae</em></p> */}
			</div>
			<div class="image-content4 animate slide delay-5"></div>
					<div class="dots animate">
						<div class="dot animate slide-up delay-6"></div>
						<div class="dot animate slide-up delay-7"></div>
						<div class="dot animate slide-up delay-8"></div>
					</div>
		</div>
			<div class="text">
				<p><img class="inset" src={img3} alt="KhiansHub" />
				<h4><a className="text-decoration-none title">One Stop Solution For Khians Needs</a></h4>

        <p class="mt-3" style={{color: "gray"}}>
            KhiansHub is your one-stop solution for all your employment needs. As an employee, you can add tasks, leaves, join letters, hike details, and more on the KhiansHub platform. It's designed to streamline your work life and make managing your career more efficient.
        </p>

        <div style={{borderLeft: "3px solid gray"}}>
            <p style={{marginLeft:"10px",color: "#379CFF"}}>
                We specialize in providing cutting-edge solutions that empower individuals to excel in their careers. Our team of experts combines technical expertise with creative vision to deliver personalized strategies for your unique journey.
            </p>
        </div>

       

        <p class="mt-3" style={{color: "gray"}}>
            KhiansHub utilizes state-of-the-art technologies to create a seamless and engaging experience for our users. From advanced algorithms to user-friendly interfaces, we leverage the latest innovations to drive your success.
        </p>
				</p>
			</div>
	</div>
    </div>

	

</div>
    </div>
       
      );
    };
 

 
export default Projectshome;
