
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../src/assets/images/KH-LOGO.png'
import Pdf from '../src/assets/pdfs/KnackHook.pdf';

const Header = () => {
  const handleClick = (e) => {
    e.preventDefault(); // Prevent default behavior of following the href
    const url = '../src/assets/pdfs/knackhook.pdf'; // Adjust the path to your PDF file
    window.open(url, '_blank'); // Open the URL in a new window/tab
  };

  return (
    <div>
      <div>
      <div className="topbar">
  <div className="container">
    <div className="row">
      <div className="col-sm-12 col-lg-8 col-md-8 col-sm-8">
        <div className="topbar-call ">
          <ul className='mt-3' >
            <li className='mt-3'><i className="fa fa-envelope-o theme-color"></i> <a href="mailto:info@knackhook.com">info@knackhook.com</a></li>
            <li className='mt-3'><i className="fa fa-phone"></i> <a href="tel:+1 (571) 222 4646"> <span>+1 (425) 548-6321 | +91 (868) 862-3071</span> </a> </li>
          </ul>
        </div>
      </div>
      <div className="col-sm-12 col-lg-4 col-md-4 col-sm-4">
        <div className="topbar-social ">
          <ul>    
 <li><a href="https://www.linkedin.com/company/knackhook/mycompany/" target="_blank" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Connect"><i class="fa fa-linkedin"></i></a></li>
<li><a href="https://twitter.com/Knack_Hook" target="_blank" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Follow" aria-describedby="tooltip805646"><i class="fa fa-twitter"></i></a></li>
<li><a href="https://www.facebook.com/knackhook/" target="_blank" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Like"><i class="fa fa-facebook"></i></a></li>		  
			  
			</ul>
        </div>
      </div>
     </div>
  </div>
</div>
     <nav  className="navbar navbar-expand-lg navbar-light ">
 <div className="container">
 

   <a className="navbar-brand" >
   <Link to="/Home">
     <img src={logo} alt="knackhook Logo" className="signin-logo" />
     </Link></a>
   <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
     {/* <span className="navbar-toggler-icon"></span> */}
     <i style={{color:"white"}} class="fa fa-bars"></i>
   </button>
   <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
     <div className="navbar-nav " style={{marginLeft:"auto"}}>
     
       <a className="nav-link scrollto active navhome" aria-current="page" style={{whiteSpace:"nowrap",padding: "10px 20px 10px 20px"}}  href="/Home">Home</a>
       <a className="nav-link scrollto navhome" style={{whiteSpace:"nowrap",padding: "10px 10px 10px 10px"}}  href="/AboutUs">About Us</a>
       {/* <a className="nav-link scrollto navhome" style={{whiteSpace:"nowrap",padding: "10px 20px 10px 28px"}}  href="/#Services">Services</a>
     */}
       <li class="nav-item dropdown dropdown-mobile">
   <a class="nav-link dropdown-toggle nav-link scrollto navhome" style={{whiteSpace:"nowrap",padding: "10px 10px 10px 10px"}}  id="navbarDropdownMobile" role="button" data-bs-toggle="dropdown" aria-expanded="false">
       Services
   </a>
   <ul class="dropdown-menu" aria-labelledby="navbarDropdownMobile">
       <li><a class="dropdown-item" href="/CloudServices">Cloud Services</a></li>
       <li><a class="dropdown-item" href="/EnterpriseSolutions">Enterprise Solutions</a></li>
       <li><a class="dropdown-item" href="/DataAnalytics">Data Analytics</a></li>
       <li><a class="dropdown-item" href="/Consulting">Consulting</a></li>
       <li><a class="dropdown-item" href="/ApplicationDevelopment">Application Development</a></li>
       <li><a class="dropdown-item" href="/ArtificialIntelligence">Artifical Intelligence</a></li>
       
   </ul>
</li> 
<a className="nav-link scrollto navhome" style={{whiteSpace:"nowrap",padding: "10px 10px 10px 10px"}}  href="/Portfolio">Portfolio</a>

       <a className="nav-link scrollto navhome" style={{whiteSpace:"nowrap",padding: "10px 10px 10px 10px"}}  href="/Projects">Projects</a>


       <li class="nav-item dropdown dropdown-mobile">
   <a class="nav-link dropdown-toggle nav-link scrollto navhome" style={{whiteSpace:"nowrap",padding: "10px 10px 10px 10px"}}  id="navbarDropdownMobile" role="button" data-bs-toggle="dropdown" aria-expanded="false">
      Careers
   </a>
   <ul class="dropdown-menu" aria-labelledby="navbarDropdownMobile">
       <li><a class="dropdown-item" href="/JobOpenings">Job Openings</a></li>
       
       
   </ul>
</li> 
       <li class="nav-item dropdown dropdown-mobile">
   <a class="nav-link dropdown-toggle nav-link scrollto navhome" style={{whiteSpace:"nowrap",padding: "10px 10px 10px 10px"}}  id="navbarDropdownMobile" role="button" data-bs-toggle="dropdown" aria-expanded="false">
       Resources
   </a>
   <ul class="dropdown-menu" aria-labelledby="navbarDropdownMobile">
   <li><a className="dropdown-item" href = {Pdf} target = "_blank">Capability Statement</a></li>

       <li><a class="dropdown-item" href="/CaseStudies">Case Studies</a></li>
       
       
       
   </ul>
</li> 
       <a className="nav-link scrollto navhome" style={{whiteSpace:"nowrap",padding: "10px 10px 10px 10px"}}  href="/ContactUs">Contact Us</a>
       
     </div>
     
   </div>
  
 </div>
</nav>

   </div>
     

    </div>

  )
}

export default Header