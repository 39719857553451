import React from 'react';
import Header from '../header';
import Footer from '../footer';
import data from '../../src/assets/images/dataanalytics.jpg';



const DataAnalytics = () => {
    return (
        
        <div>
        <Header/>
<div className="container mt-5 mb-5">
    <div className="row">

        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
    <h1 Alt="Data Integration and ETL Tools" style={{color:"#379CFFFC",fontSize:'30px'}}>Data Analytics</h1>

            <p>"KnackHook leverages advanced analytics and cutting-edge technology to transform data into actionable insights. Our solutions empower businesses to make data-driven decisions for sustainable growth."</p>
            <p> Empower your organization with data-driven insights that fuel growth and innovation. With our advanced analytics capabilities,
 we help you identify market trends, understand customer behavior, and optimize operations for maximum efficiency and profitability."
 "Gain a competitive edge with our data analytics solutions, designed to uncover actionable insights, drive strategic decision-making, 
 and fuel business success.</p>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <img src={data} alt="About Knackhook" class="about-images2 img-fluid"/>

        </div>
    </div>
 
<div className="row mt-5">
<div className="col-sm-12 col-xl-4 col-lg-4 col-md-12">
    <div className='card data-card mt-4' style={{background:'tranparent',border:'1px solid grey'}}>
    <h1 Alt="Data Integration and ETL Tools" style={{color:"#379CFFFC",textAlign:'center',fontSize:'20px'}}>Data Integration and ETL Tools</h1>
<p>KnackHook specializes in building custom data integration solutions that streamline the ETL (Extract, Transform, Load) process. Our tools seamlessly extract data from diverse sources, transform it to meet your specific requirements, and load it into a unified repository. With our expertise, ensure data consistency and reliability for enhanced analytics and decision-making.</p>
    </div>
</div>
<div className="col-sm-12 col-xl-4 col-lg-4 col-md-12">
<div className='card data-card mt-4' style={{background:'tranparent'}}>
    <h5 style={{color:"#379CFFFC",textAlign:'center'}}>Data Warehousing Solutions</h5>
<p>Partner with KnackHook for tailored data warehousing solutions that efficiently store and organize large volumes of structured and unstructured data. Our expertise lies in designing scalable and high-performance data warehouses that enable fast and efficient access for analytics, reporting, and decision support.</p>
    </div>
</div>
<div className="col-sm-12 col-xl-4 col-lg-4 col-md-12">
<div className='card data-card mt-4' style={{background:'transparent'}}>
    <h5 style={{color:"#379CFFFC",textAlign:'center'}}>Data Visualization Platforms</h5>
<p>Elevate your data insights with KnackHook's custom data visualization platforms. We specialize in transforming complex datasets into intuitive visualizations, including charts, graphs, and maps. Our solutions empower users to identify trends, patterns, and insights effortlessly, facilitating informed decision-making and driving business growth.</p>
    </div>
</div>
</div>

</div>
<Footer/>
</div>
);
};

export default DataAnalytics;
