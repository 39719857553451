import React from 'react';
import Header from '../header';
import Footer from '../footer';
import image1 from '../../src/assets/images/baner1 (1).jpg'
import applicationimage from '../../src/assets/images/applicationdevelopment.jpg'
import artificialinteligence from '../../src/assets/images/artificialinteligence.jpg'
import dataanalytics from '../../src/assets/images/dataanalytics.jpg'
import erp from '../../src/assets/images/enterprise-resource-planning-holographic-interface.jpg'



const EnterpriseSolutions = () => {
    return (
        
<div>
<Header/>
<section  id="banner" style={{ 
      backgroundColor: 'transparent',
      backgroundImage: 'radial-gradient(at bottom right, #3758FF7D 0%, #030510 64%)'
    }}>
   <div class="container pt-5">
      <div class="elementor-background-overlay" style={{
  backgroundImage: 'url(https://kit.baliniz.com/virtty/wp-content/uploads/sites/55/2023/05/noise.png)',
  backgroundPosition: 'center center',
  backgroundRepeat: 'repeat',
  backgroundSize: '14% auto',
  opacity: 0.5,
  transition: 'background 0.3s, border-radius 0.3s, opacity 0.3s'
}}></div>
    
         
   
         <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
               <div class="text-center">
               <h2 class="head3b">
            <span class="dynamic-wrapper style-gradient">
            <span class="dynamic-text" >Enterprise Solutions</span> 
         </span>
         <p style={{fontSize:"17px"}} class="mt-3">"Unlock the full potential of your enterprise with our end-to-end solutions. From implementing cutting-edge ERP systems to deploying innovative
 CRM solutions, we're dedicated to driving efficiency, productivity, and growth for your business."</p>
                 
         <span class="normal-text style-gradient" ></span>
         </h2>

               </div>
            </div>
           
         
         </div>
   </div>
   </section>
   <div className="container">
    <div className="text-center mt-5">
       <p>Our company specializes in providing comprehensive enterprise solutions tailored to meet the diverse needs of large organizations. We offer a range of innovative software and services designed to streamline operations, enhance productivity, and drive growth across various industries.</p>

    </div>
    <div className="card p-5 mt-5" style={{backgroundColor:"transparent",border: "1px solid gray",borderRadius:"30px"}}>
        <div className="row">
        <div className="col-sm-12 col-lg-6 col-xl-6 col-md-6">
            <div>
        <img  class="enterpriseimg img-fluid " src={image1} alt="KnackHook" style={{height:"636px",borderRadius:'20px 20px 20px 20px',objectFit:'cover'}}/>
        </div>
        </div>
        <div className="col-sm-12 col-lg-6 col-xl-6 col-md-6">
               <div class="row mt-4">
                <div class="col-sm-12 col-lg-4 col-xl-4 col-md-4 text-center">
                <img  class="img-fluid" src={erp} alt="KnackHook" style={{height:'160px',borderRadius:'20px 20px 20px 20px',objectFit:'cover',marginTop:'40px'}}/>

                </div>
                <div class="col-sm-12 col-xl-8 col-lg-8 col-md-8">
                   <h1 Alt="Enterprise Resource Planning (ERP) Systems"style={{fontSize:'18px', verticalAlign:'middle',marginTop:'20px',color:'#379CFFFC',fontSize:'20px'}}>Enterprise Resource Planning (ERP) Systems:</h1>
               <p>Leverage KnackHook's deep expertise in ERP system development to streamline your business processes. Our tailored solutions are designed to enhance efficiency and decision-making across finance, HR, supply chain, and manufacturing, ensuring seamless integration and optimization of your operations.
</p> 
               {/*<p style={{color:'#379CFFFC',fontWeight:'bold'}}>Read More</p>*/}
               </div>
               </div>
                        
               <div class="row mt-4">
                <div class="col-sm-12 col-lg-4 col-xl-4 col-md-4 text-center">
                <img  class="img-fluid" src={artificialinteligence} alt="KnackHook" style={{height:'160px',borderRadius:'20px 20px 20px 20px',objectFit:'cover',marginTop:'40px'}}/>

                </div>
                <div class="col-sm-12 col-xl-8 col-lg-8 col-md-8">
                   <h5 style={{fontSize:'18px',verticalAlign:'middle',marginTop:'20px',color:'#379CFFFC'}}>Customer Relationship Management (CRM) Software:</h5>
               <p>Empower your business with KnackHook's customized CRM software development services. We specialize in crafting personalized CRM platforms that enable you to manage customer interactions, track sales, and deliver exceptional experiences. With our expertise, enhance customer satisfaction and drive growth through effective relationship management.</p> 
               {/*<p style={{color:'#379CFFFC',fontWeight:'bold'}}>Read More</p>*/}
               </div>
               </div>
               <div class="row mt-4">
                <div class="col-sm-12 col-lg-4 col-xl-4 col-md-4 text-center">
                <img  class="img-fluid" src={dataanalytics} alt="KnackHook" style={{height:'160px',borderRadius:'20px 20px 20px 20px',objectFit:'cover',marginTop:'40px'}}/>
                </div>
                <div class="col-sm-12 col-xl-8 col-lg-8 col-md-8">
                   <h5 style={{fontSize:'18px',verticalAlign:'middle',marginTop:'20px',color:'#379CFFFC'}}>Business Intelligence (BI) and Analytics Platforms:</h5>
               <p>Harness the power of data with KnackHook's bespoke BI and analytics platform development. Our solutions provide actionable insights that fuel informed decision-making and business growth. Partner with us to unlock the full potential of your data, drive innovation, and stay ahead in today's competitive landscape.</p> 
              {/* <p style={{color:'#379CFFFC',fontWeight:'bold'}}>Read More</p>*/}
               </div>
               </div>
        </div>
        </div>
    </div>
   </div>
<Footer/>

</div>
);
};

export default EnterpriseSolutions;
