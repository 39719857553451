import React from 'react';
import Header from '../header';
import Footer from '../footer';
import image1 from '../../src/assets/images/contact1.png'



const JobOpenings = () => {
    return (
        <div>
        <Header/>
        <section  id="banner" style={{ 
      backgroundColor: 'transparent',
      backgroundImage: 'radial-gradient(at bottom right, #3758FF7D 0%, #030510 64%)'
    }}>
   <div class="container pt-5">
      <div class="elementor-background-overlay" style={{
  backgroundImage: 'url(https://kit.baliniz.com/virtty/wp-content/uploads/sites/55/2023/05/noise.png)',
  backgroundPosition: 'center center',
  backgroundRepeat: 'repeat',
  backgroundSize: '14% auto',
  opacity: 0.5,
  transition: 'background 0.3s, border-radius 0.3s, opacity 0.3s'
}}></div>
    
         
   
         <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
               <div class="text-center">
               <h2 class="head3b">
            <span class="dynamic-wrapper style-gradient">
            <span class="dynamic-text" >Job Openings</span> 
         </span>
         <p style={{fontSize:"17px"}} class="mt-3">KnackHook, The Genius Network, is helmed by a cadre of IT experts since 2016. Our mission is to amplify the value of your resources and investments. With a worldwide delivery model, we guarantee client contentment.</p>
                 
         <span class="normal-text style-gradient" ></span>
         </h2>

               </div>
            </div>
           
         
         </div>
   </div>
   </section>
   <div className="container mt-5 mb-5">
 
        <div class="row">
                <div className="col-sm-12 col-lg-6 col-xl-6 col-md-6">
                <div class="head2a"style={{ textAlign: 'left' , marginTop:'50px'}}>
         <h2 class="head2b" >
            <span class="dynamic-wrapper style-gradient">
            <span class="dynamic-text" >Join the KnackHook Team
            </span></span>
            <span class="normal-text style-gradient" ></span>
         </h2>
         <p className='mt-3'>At KnackHook, we're always on the lookout for talented individuals who are passionate about technology, innovation, and making a difference. If you're looking for a dynamic and rewarding career opportunity, you've come to the right place. Explore our current job openings below and take the first step towards becoming a part of the KnackHook family.</p>

      </div>
                </div>
                <div className="col-sm-12 col-lg-6 col-xl-6 col-md-6">
                <div class="head2a"style={{ textAlign: 'left' , marginTop:'50px'}}>
                  
           {/* <img src={image1} style={{marginTop:'50px'}} class="contactimage2 img-fluid"alt="contact" />*/}
           <h2 class="head2b" >
            <span class="dynamic-wrapper style-gradient">
            <span class="dynamic-text" >Why Work at KnackHook?
            </span></span>
            <span class="normal-text style-gradient" ></span>
         </h2>
         </div>
         <ul style={{marginTop:'20px',color:'#fff'}}>
            <li><b>Innovative Projects:</b> Join a team that thrives on innovation and creativity. At KnackHook, you'll have the opportunity to work on cutting-edge projects that push the boundaries of technology and drive real-world impact.</li>
            <li><b>Collaborative Culture: </b>We believe in the power of collaboration and teamwork. As a KnackHook employee, you'll work alongside talented professionals who are dedicated to helping each other succeed and achieve greatness.</li>
            
            </ul>
                </div>
         </div>
    
   
   <div class="row mt-5">
   <h2 class="head2b" >
            <span class="dynamic-wrapper style-gradient">
            <span class="dynamic-text text-center" >Job Opportunities!
            </span></span>
            <span class="normal-text style-gradient" ></span>
         </h2>
         <div class="col-sm-12 col-md-6 col-xl-6 col-lg-6">
            <div class="accordion accordion-flush mt-5" id="accordionFlushExample" >
               <div class="accordion-item" >
                 <h2 class="accordion-header" id="flush-headingOne">
                   <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                   .NET Full-Stack Developer
                   </button>
                 </h2>
                 <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                   <div class="accordion-body">
                     <div class="row">
                       
                     <div class="">
                              
                              <h5>Responsibilities:</h5>
                              <ul style={{color:'#fff'}}>
                                 <li>Develop, test, and deploy .NET-based applications, both frontend and backend components.</li>
                                 <li>Collaborate with cross-functional teams to design and implement scalable software solutions.</li>
                                 <li>Write clean, maintainable code following best practices and industry standards.</li>
                                 <li>Troubleshoot and debug applications to resolve issues and optimize performance.</li>
                                 </ul>
                                 <h5>Qualifications:</h5>
                                 <ul style={{color:'#fff'}}>
                                 <li>Proficiency in .NET framework, C#, ASP.NET MVC, and related technologies.</li>
                                 <li>Experience with frontend technologies such as HTML, CSS, JavaScript, and frameworks like Angular or React.</li>
                                 <li>Strong understanding of database systems and SQL.</li>
                                 <li>Excellent problem-solving and communication skills.</li>
                                 </ul>
                              <div className="topbar-call ">  
          <ul className='mt-3 jobopen'>
            <li ><i className="fa fa-envelope-o theme-color"></i> <a style={{textDecoration:'none'}} href="mailto:info@knackhook.com">info@knackhook.com</a></li>
            <li ><i className="fa fa-phone"></i> <a style={{textDecoration:'none'}} href="tel:+1 (571) 222 4646"> <span>+1 (425) 548-6321</span> </a> </li>
          </ul></div>
     
                                                         </div>
                                                     
                     </div>
                   </div>
                 </div>
               </div>
               
             </div>
   
   
             <div class="accordion accordion-flush mt-4" id="accordionFlushExample" >
               <div class="accordion-item" >
                 <h2 class="accordion-header" id="flush-headingtwo">
                   <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsetwo" aria-expanded="false" aria-controls="flush-collapsetwo">
                   Azure Data Engineer
                   </button>
                 </h2>
                 <div id="flush-collapsetwo" class="accordion-collapse collapse" aria-labelledby="flush-headingtwo" data-bs-parent="#accordionFlushExample">
                   <div class="accordion-body">
                     <div class="row">
                       
                     <div class="">
                              
                              <h5>Responsibilities:</h5>
                              <ul style={{color:'#fff'}}>
                                 <li>Design, implement, and maintain data pipelines and ETL processes on Azure data services.</li>
                                 <li>Develop and deploy data solutions leveraging Azure technologies such as Azure Data Factory, Azure Databricks, and Azure Synapse Analytics.</li>
                                 <li>Collaborate with stakeholders to gather requirements and design data architectures that meet business needs.</li>
                                 <li>Ensure data security, integrity, and compliance with regulatory standards.</li>
                                 </ul>
                                 <h5>Qualifications:</h5>
                                 <ul style={{color:'#fff'}}>
                                 <li>Proficiency in Azure cloud services and data-related technologies.</li>
                                 <li>Experience with data modeling, data warehousing, and big data technologies.</li>
                                 <li>Strong programming skills in languages like Python, SQL, or Scala.</li>
                                 <li>Familiarity with DevOps practices for continuous integration and deployment.</li>
                                 </ul>
                              <div className="topbar-call ">  
          <ul className='mt-3 jobopen'>
            <li ><i className="fa fa-envelope-o theme-color"></i> <a style={{textDecoration:'none'}} href="mailto:info@knackhook.com">info@knackhook.com</a></li>
            <li ><i className="fa fa-phone"></i> <a style={{textDecoration:'none'}} href="tel:+1 (571) 222 4646"> <span>+1 (425) 548-6321</span> </a> </li>
          </ul></div>
     
                                                         </div>
                       
                     </div>
                   </div>
                 </div>
               </div>
               
             </div>
   
   
            
         </div>
         <div class="col-sm-12 col-md-12 col-xl-6 col-lg-6" >
             <div class="accordion accordion-flush mt-5" id="accordionFlushExample" >
               <div class="accordion-item" >
                 <h2 class="accordion-header" id="flush-headingthree">
                   <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsethree" aria-expanded="false" aria-controls="flush-collapsethree">
                   Project Manager
                   </button>
                 </h2>
                 <div id="flush-collapsethree" class="accordion-collapse collapse" aria-labelledby="flush-headingthree" data-bs-parent="#accordionFlushExample">
                   <div class="accordion-body">
                     <div class="row">
                     <div class="">
                              
                              <h5>Responsibilities:</h5>
                              <ul style={{color:'#fff'}}>
                                 <li>Plan, execute, and monitor projects from initiation to closure, ensuring deliverables are completed on time, within budget, and according to scope.</li>
                                 <li>Define project objectives, timelines, resource requirements, and dependencies.</li>
                                 <li>Coordinate cross-functional teams, assign tasks, and facilitate communication to drive project success.</li>
                                 <li>Identify and mitigate risks, resolve issues, and escalate as needed to ensure project milestones are achieved.</li>
                                 </ul>
                                 <h5>Qualifications:</h5>
                                 <ul style={{color:'#fff'}}>
                                 <li>Proven experience in project management, preferably in IT or software development projects.</li>
                                 <li>Strong leadership, communication, and interpersonal skills.</li>
                                 <li>Excellent organizational and time-management abilities.</li>
                                 <li>Knowledge of project management methodologies such as Agile or Scrum.</li>
                                 </ul>
                              <div className="topbar-call ">  
          <ul className='mt-3 jobopen' >
            <li ><i className="fa fa-envelope-o theme-color"></i> <a style={{textDecoration:'none'}} href="mailto:info@knackhook.com">info@knackhook.com</a></li>
            <li ><i className="fa fa-phone"></i> <a style={{textDecoration:'none'}} href="tel:+1 (571) 222 4646"> <span>+1 (425) 548-6321</span> </a> </li>
          </ul></div>
     
                                                         </div>
                                                     
                     </div>
                   </div>
                 </div>
               </div>
               
             </div>
   
   
             <div class="accordion accordion-flush mt-4" id="accordionFlushExample" >
               <div class="accordion-item" >
                 <h2 class="accordion-header" id="flush-headingnet">
                   <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsenet" aria-expanded="false" aria-controls="flush-collapsenet">
                   Network Engineer
                   </button>
                 </h2>
                 <div id="flush-collapsenet" class="accordion-collapse collapse" aria-labelledby="flush-headingnet" data-bs-parent="#accordionFlushExample">
                   <div class="accordion-body">
                     <div class="row">
                       
                     <div class="">
                              
                              <h5>Responsibilities:</h5>
                              <ul style={{color:'#fff'}}>
                                 <li>Design, implement, and maintain network infrastructure, including routers, switches, firewalls, and VPNs.</li>
                                 <li>Monitor network performance, troubleshoot issues, and implement solutions to optimize reliability and security.</li>
                                 <li>Perform network upgrades, migrations, and capacity planning to support business requirements.</li>
                                 <li>Collaborate with other IT teams to integrate network systems with other technologies.</li>
                                 </ul>
                                 <h5>Qualifications:</h5>
                                 <ul style={{color:'#fff'}}>
                                 <li>Solid understanding of networking concepts, protocols, and technologies.</li>
                                 <li>Experience with network equipment and tools from vendors such as Cisco, Juniper, or Palo Alto.</li>
                                 <li>Knowledge of security best practices and experience with implementing network security measures.</li>
                                 <li>Relevant certifications such as CCNA or CCNP are a plus.</li>
                                 </ul>
                              <div className="topbar-call ">  
          <ul className='mt-3 jobopen'>
            <li ><i className="fa fa-envelope-o theme-color"></i> <a style={{textDecoration:'none'}} href="mailto:info@knackhook.com">info@knackhook.com</a></li>
            <li ><i className="fa fa-phone"></i> <a style={{textDecoration:'none'}} href="tel:+1 (571) 222 4646"> <span>+1 (425) 548-6321</span> </a> </li>
          </ul></div>
     
                                                         </div>
                       
                     </div>
                   </div>
                 </div>
               </div>
               
             </div>
         </div>
      </div>

      <div class="row mt-1">
  <div class="col-sm-12 col-md-6 col-xl-6 col-lg-6">
     <div class="accordion accordion-flush mt-5" id="accordionFlushExamplefive" >
        <div class="accordion-item" >
           <h2 class="accordion-header" id="flush-headingfive">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapsefive">
              Data Analyst
              </button>
           </h2>
           <div id="flush-collapsefive" class="accordion-collapse collapse" aria-labelledby="flush-headingfiev" data-bs-parent="#accordionFlushExamplefive">
              <div class="accordion-body">
                 <div class="row">
                 <div class="">
                              
                              <h5>Responsibilities:</h5>
                              <ul style={{color:'#fff'}}>
                                 <li>Collect, clean, and analyze data from various sources to extract actionable insights and support decision-making.</li>
                                 <li>Develop and maintain dashboards, reports, and data visualizations to communicate findings to stakeholders.</li>
                                 <li>Identify trends, patterns, and anomalies in data sets and provide recommendations for improvement.</li>
                                 <li>Collaborate with business units to define data requirements and KPIs.</li>
                                 </ul>
                                 <h5>Qualifications:</h5>
                                 <ul style={{color:'#fff'}}>
                                 <li>Proficiency in data analysis tools and programming languages such as SQL, Python, or R.</li>
                                 <li>Experience with data visualization tools such as Tableau, Power BI, or D3.js.</li>
                                 <li>Strong analytical and problem-solving skills.</li>
                                 <li>Excellent attention to detail and ability to work with large datasets.</li>
                                 </ul>
                              <div className="topbar-call ">  
          <ul className='mt-3 jobopen' >
            <li ><i className="fa fa-envelope-o theme-color"></i> <a style={{textDecoration:'none'}} href="mailto:info@knackhook.com">info@knackhook.com</a></li>
            <li ><i className="fa fa-phone"></i> <a style={{textDecoration:'none'}} href="tel:+1 (571) 222 4646"> <span>+1 (425) 548-6321</span> </a> </li>
          </ul></div>
     
                                                         </div>
                 </div>
              </div>
           </div>
        </div>
     </div>
     <div class="accordion accordion-flush mt-4" id="accordionFlushExamplesix" >
        <div class="accordion-item" >
           <h2 class="accordion-header" id="flush-headingsix">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsesix" aria-expanded="false" aria-controls="flush-collapsesix">
              System Support Engineer
              </button>
           </h2>
           <div id="flush-collapsesix" class="accordion-collapse collapse" aria-labelledby="flush-headingsix" data-bs-parent="#accordionFlushExamplesix">
              <div class="accordion-body">
                 <div class="row">
                 <div class="">
                              
                              <h5>Responsibilities:</h5>
                              <ul style={{color:'#fff'}}>
                                 <li>Provide technical support and troubleshooting assistance to end-users for hardware, software, and network-related issues.</li>
                                 <li>Install, configure, and maintain IT systems and infrastructure, including servers, workstations, and peripherals.</li>
                                 <li>Respond to service requests, resolve incidents, and escalate complex issues as needed.</li>
                                 <li>	Document support processes, procedures, and resolutions for knowledge sharing.</li>
                                 </ul>
                                 <h5>Qualifications:</h5>
                                 <ul style={{color:'#fff'}}>
                                 <li>Solid understanding of IT systems, networks, and operating systems.</li>
                                 <li>Experience with troubleshooting hardware, software, and network problems.</li>
                                 <li>Strong customer service and communication skills.</li>
                                 <li>Certifications such as CompTIA A+, Microsoft Certified Desktop Support Technician (MCDST), or equivalent are a plus.</li>
                                 </ul>
                              <div className="topbar-call ">  
          <ul className='mt-3 jobopen' >
            <li ><i className="fa fa-envelope-o theme-color"></i> <a style={{textDecoration:'none'}} href="mailto:info@knackhook.com">info@knackhook.com</a></li>
            <li ><i className="fa fa-phone"></i> <a style={{textDecoration:'none'}} href="tel:+1 (571) 222 4646"> <span>+1 (425) 548-6321</span> </a> </li>
          </ul></div>
     
                                                         </div>
                 </div>
              </div>
           </div>
        </div>
     </div>
  </div>
  <div class="col-sm-12 col-md-12 col-xl-6 col-lg-6" >
     <div class="accordion accordion-flush mt-5" id="accordionFlushExampleseven" >
        <div class="accordion-item" >
           <h2 class="accordion-header" id="flush-headingseven">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseseven" aria-expanded="false" aria-controls="flush-collapseseven">
             UI/UX Designer
              </button>
           </h2>
           <div id="flush-collapseseven" class="accordion-collapse collapse" aria-labelledby="flush-headingseven" data-bs-parent="#accordionFlushExampleseven">
              <div class="accordion-body">
                 <div class="row">
                 <div class="">
                              
                              <h5>Responsibilities:</h5>
                              <ul style={{color:'#fff'}}>
                                 <li>Design user interfaces and experiences for web and mobile applications that are intuitive, visually appealing, and user-friendly.</li>
                                 <li>Conduct user research, usability testing, and gather feedback to inform design decisions.</li>
                                 <li>Create wireframes, prototypes, and mockups to illustrate design concepts and workflows.</li>
                                 <li>Collaborate with cross-functional teams including developers, product managers, and stakeholders to iterate and refine designs.	</li>
                                 </ul>
                                 <h5>Qualifications:</h5>
                                 <ul style={{color:'#fff'}}>
                                 <li>Proficiency in design tools such as Adobe XD, Sketch, or Figma.</li>
                                 <li>Strong understanding of user-centered design principles and methodologies.</li>
                                 <li>Experience with responsive design and designing for various screen sizes and devices.</li>
                                 <li>Experience with responsive design and designing for various screen sizes and devices.</li>
                                 </ul>
                              <div className="topbar-call ">  
          <ul className='mt-3 jobopen'>
            <li ><i className="fa fa-envelope-o theme-color"></i> <a style={{textDecoration:'none'}} href="mailto:info@knackhook.com">info@knackhook.com</a></li>
            <li ><i className="fa fa-phone"></i> <a style={{textDecoration:'none'}} href="tel:+1 (571) 222 4646"> <span>+1 (425) 548-6321</span> </a> </li>
          </ul></div>
     
                                                         </div>
                 </div>
              </div>
           </div>
        </div>
     </div>
     <div class="accordion accordion-flush mt-4" id="accordionFlushExampleeight" >
        <div class="accordion-item" >
           <h2 class="accordion-header" id="flush-headingeight">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseeight" aria-expanded="false" aria-controls="flush-collapseeight">
            Data Scientist
              </button>
           </h2>
           <div id="flush-collapseeight" class="accordion-collapse collapse" aria-labelledby="flush-headingeight" data-bs-parent="#accordionFlushExampleeight">
              <div class="accordion-body">
                 <div class="row">
                 <div class="">
                              
                              <h5>Responsibilities:</h5>
                              <ul style={{color:'#fff'}}>
                                 <li>Utilize statistical analysis and machine learning techniques to extract insights from large datasets and solve complex business problems.</li>
                                 <li>	Develop predictive models, algorithms, and data-driven solutions to optimize processes and drive business outcomes.</li>
                                 <li>Collaborate with cross-functional teams to understand business requirements and translate them into data science solutions.</li>
                                 <li>Communicate findings and recommendations to stakeholders through reports, presentations, and data visualizations.</li>
                                 </ul>
                                 <h5>Qualifications:</h5>
                                 <ul style={{color:'#fff'}}>
                                 <li>	Proficiency in programming languages such as Python or R for data analysis and machine learning.</li>
                                 <li>Experience with statistical analysis, data mining, and predictive modeling techniques.</li>
                                 <li>Knowledge of machine learning libraries and frameworks such as scikit-learn, TensorFlow, or PyTorch.</li>
                                 <li>Strong problem-solving skills and ability to work with large and complex datasets.</li>
                                 </ul>
                              <div className="topbar-call ">  
          <ul className='mt-3 jobopen' >
            <li ><i className="fa fa-envelope-o theme-color"></i> <a style={{textDecoration:'none'}} href="mailto:info@knackhook.com">info@knackhook.com</a></li>
            <li ><i className="fa fa-phone"></i> <a style={{textDecoration:'none'}} href="tel:+1 (571) 222 4646"> <span>+1 (425) 548-6321</span> </a> </li>
          </ul></div>
     
                                                         </div>
                 </div>
              </div>
           </div>
        </div>
     </div>
  </div>
</div>
      </div>
      <Footer/>
        </div>
);
};

export default JobOpenings;