import React from 'react';
import Header from '../header';
import Footer from '../footer';
import Artificial from '../../src/assets/images/ai-n.png'
const ArtificialIntelligence = () => {
return (
<div>
   <Header/>
   <img src={Artificial} alt="About Knackhook" style={{width:'100%',objectFit:'cover',height:'350px'}} class="ai-image"/>
   <div className="container mt-5 mb-5">
      <h1 Alt="artificial intelligence" style={{color:"#379CFFFC",fontSize:'25px'}}>Artificial Intelligence</h1>
      <p className='mt-3' style={{color:"#fff"}}>"KnackHook leverages cutting-edge AI technologies to provide solutions that enhance automation, decision-making, and business intelligence. Our AI-driven solutions empower businesses to achieve new levels of efficiency and innovation."</p>
      <p className='mt-3' style={{color:"#fff"}}> AI technologies empower businesses to automate processes, gain insights from data, and deliver personalized experiences. From machine learning algorithms to natural language processing, AI solutions enable organizations to innovate and stay competitive in today's digital landscape.</p>
      <div className="card p-5 mt-5" style={{borderRadius:'30px',border:'1px solid grey',background:'none'}}>
      <div className="row" >
        <div style={{display:'flex'}}>
         <div className="mt-5" style={{color: '#fff',
         borderStyle:'solid',
         borderWidth: '1px 1px 1px 1px',
         borderColor: '#379CFFFC',
         borderRadius: '50%',
         height:'40px',
         width:'40px',
         boxShadow: '0px 0px 6px 0px rgba(54.99999999999997, 156.00000000000023, 255, 0.9882352941176471)',
         margin: '0px 8px 0px 0px',
         padding: '9px 9px 9px 9px'}}>
         <i class="fa fa-arrow-right " aria-hidden="true" style={{color: '#fff',fontSize:'20px',marginLeft:'5px',marginLeft:'5px'}}></i>
      </div>
      <div className="mt-5">
         <h5 style={{color:"#379CFFFC"}}>
            Machine Learning Solutions:
         </h5>
         <p>Our company provides machine learning solutions to build predictive models, automate decision-making processes, and uncover patterns in data to drive business insights and outcomes.</p>
      </div>
      </div>
   </div>
   <div className="row">
   <div style={{display:'flex'}}>
    
         <div  className="mt-5" style={{color: '#fff',
         borderStyle:'solid',
         borderWidth: '1px 1px 1px 1px',
         borderColor: '#379CFFFC',
         borderRadius: '50%',
         height:'40px',
         width:'40px',
         boxShadow: '0px 0px 6px 0px rgba(54.99999999999997, 156.00000000000023, 255, 0.9882352941176471)',
         margin: '0px 8px 0px 0px',
         padding: '9px 9px 9px 9px'}}>
         <i class="fa fa-arrow-right " aria-hidden="true" style={{color: '#fff',marginLeft:'5px',fontSize:'20px',marginLeft:'5px'}}></i>
      </div>
   
   <div className="mt-5">
      <h5 style={{color:"#379CFFFC"}}>Natural Language Processing (NLP):</h5>
      <p>We offer NLP services to analyze and understand human language, enabling businesses to extract valuable insights from unstructured text data, automate customer interactions, and improve communication.</p>
   </div>
   </div>
</div>
<div className="row">
   <div style={{display:'flex',}}>
      <div className="mt-5"  style={{color: '#fff',
      borderStyle:'solid',
      borderWidth: '1px 1px 1px 1px',
      borderColor: '#379CFFFC',
      borderRadius: '50%',
      height:'40px',
      width:'40px',
      boxShadow: '0px 0px 6px 0px rgba(54.99999999999997, 156.00000000000023, 255, 0.9882352941176471)',
      margin: '0px 8px 0px 0px',
      padding: '9px 9px 9px 9px'}}>
      <i class="fa fa-arrow-right " aria-hidden="true" style={{color: '#fff',fontSize:'20px',marginLeft:'5px',marginLeft:'5px'}}></i>
   </div>
   <div className="mt-5">
   <h5 style={{color:"#379CFFFC"}}>Computer Vision Solutions:</h5>
   <p>Our computer vision solutions leverage AI algorithms to analyze and interpret visual data, enabling applications such as image recognition, object detection, and video analytics for various industries, including retail, healthcare, and manufacturing.
   </p>
</div>
</div>

</div>
</div>
</div>
<Footer/>
</div>
);
};
export default ArtificialIntelligence;