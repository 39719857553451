import React, { useState ,useEffect} from 'react';

import image from '../../src/assets/images/mainbanner.png'
import image1 from '../../src/assets/images/baner1 (1).jpg'
import { Card } from '@mui/material'

import {Link} from 'react-router-dom';


const Section = () => {
   const [clicked, setClicked] = useState(false);
  
  return (
   <section  id="banner" style={{ 
      backgroundColor: 'transparent',
      backgroundImage: 'radial-gradient(at bottom right, #3758FF7D 0%, #030510 64%)'
    }}>
   <div class="container pt-5">
      <div class="elementor-background-overlay" style={{
  backgroundImage: 'url(https://kit.baliniz.com/virtty/wp-content/uploads/sites/55/2023/05/noise.png)',
  backgroundPosition: 'center center',
  backgroundRepeat: 'repeat',
  backgroundSize: '14% auto',
  opacity: 0.5,
  transition: 'background 0.3s, border-radius 0.3s, opacity 0.3s'
}}></div>
    
         <h2 class="head2b" >
            <span class="dynamic-wrapper style-gradient">
            <span class="dynamic-text" >We Maximize Your Vision and Reflect the Personality of Your Business</span> 
         </span>
         <p style={{fontSize:"17px"}} class="mt-3">KnackHook, The Genius Network, is helmed by a cadre of IT experts since 2016. Our mission is to amplify the value of your resources and investments. With a worldwide delivery model, we guarantee client contentment.</p>
         <Link to="/ContactUs">    <button type="button" class="btn btn-primary mt-3" onClick={() => setClicked(true)}>Get Started<i class="fa fa-arrow-right ml-5" style={{
  color: '#379CFF',
  marginLeft: '15px'
}}  aria-hidden="true"></i></button>   </Link>          
         <span class="normal-text style-gradient" ></span>
         </h2>
   
         <div class="row">
            <div class="col-sm-12 col-md-8 col-lg-8 col-xl-8">
               <div class="text-center">
                  <img  class="img-fluid" src={image} alt="KnackHook"  style={{
  height: '450px',
  width: '450px'
}}/>
               </div>
            </div>
            <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
               <p style={{fontSize:"17px"}} >KnackHook is a leading provider of comprehensive corporate solutions, including software development, recruitment services, and customized learning solutions for core software and emerging technologies. We excel in delivering top-tier services to clients worldwide, helping them thrive in today's competitive business landscape.</p>
               <img src={image1} alt="KnackHook" class="img-fluid" style={{
  
  borderRadius: '20px'
}}/> {/*
<span><button type="button" class="btn btn-danger" style={{
//   backgroundColor: "rgb(255, 255, 255)",
//   height: "60px",
//   width: "60px",
//   borderRadius: "50%",
//   float: "right",
//   marginLeft: "-44px",
//   marginTop: "115px",
//   border: "0px"
backgroundColor: "rgb(255, 255, 255)",
height: "40px",
width: "40px",
borderRadius: "50%",
marginLeft: "-40px",
marginTop: "-12px",
border: "0px"
  
}}>
   <i  style={{color:"rgb(55, 156, 255)"}} class="fa fa-arrow-right ml-5"  aria-hidden="true"></i></button></span>

               <hr/>
               <div class="row">
                  <div class="col-10 col-xl-10 col-lg-10">
                     
                       <img src={image1} alt="KnackHook" class="img-fluid" style={{
  height: '200px',
  width: '500px',
  borderRadius: '20px'
}}/>

   
                  </div>
                  <div class="col-2 col-xl-2 col-lg-2">
         <button type="button" class="btn btn-danger" style={{
  backgroundColor: "rgb(255, 255, 255)",
  height: "40px",
  width: "40px",
  borderRadius: "50%",
  marginLeft: "-48px",
  marginTop: "98px",
  border: "0px"
}}><i  style={{color:"rgb(55, 156, 255)"}} class="fa fa-arrow-right ml-5"  aria-hidden="true"></i></button>
                     
                  </div>
               </div> */}
            </div>
         
         </div>
   </div>
   </section>

  )
}

export default Section