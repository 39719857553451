import React from 'react';
import Header from '../header';
import Footer from '../footer';
import application from '../../src/assets/images/app.jpg'
import app from '../../src/assets/images/app.jpg'


const ApplicationDevelopment = () => {
    return (
        
        <div>
        <Header/>
        <img src={app} alt="About Knackhook" style={{width:'100%',objectFit:'cover',height:'350px'}} class="app-image"/>
<div className="container mt-5">
   
<div className='row mt-5 mb-5'>
    <div className='col-sm-12 col-lg-6 col-xl-6 col-md-6'>
    <ul><h5 style={{color:"#379CFFFC"}}>Enterprise Application Development:</h5>
       <li style={{color:"#fff"}}>Our team provides enterprise application development services to build scalable, secure, and mission-critical software solutions that automate business processes, enhance productivity, and facilitate collaboration across the organization.
</li>
   </ul>
   <ul><h5 style={{color:"#379CFFFC"}}>E-commerce Development:</h5>
       <li style={{color:"#fff"}}>We specialize in e-commerce development to create user-friendly and visually appealing online stores, marketplaces, and platforms that drive sales, enhance customer experiences, and optimize conversion rates.

</li>
   </ul>
    </div>
    <div className='col-sm-12 col-lg-6 col-xl-6 col-md-6'>
    <ul><h5 style={{color:"#379CFFFC"}}>Cloud Application Development:</h5>
       <li style={{color:"#fff"}}>Our cloud application development services leverage cloud technologies and platforms, such as AWS, Azure, and Google Cloud, to build scalable, resilient, and cost-effective cloud-native applications that enable businesses to innovate and scale rapidly.</li>
   </ul>
   <ul><h1 Alt="Mobile app"style={{color:"#379CFFFC",fontSize:'20px'}}>Mobile App Prototyping and MVP Development:</h1>
       <li style={{color:"#fff"}}>Our mobile app development services include prototyping and MVP development to validate ideas, gather feedback, and iterate quickly, enabling businesses to bring their mobile app concepts to market faster and more cost-effectively.</li>
   </ul>
    </div>
   
   
</div>
</div>
<Footer/>
</div>

);
};

export default ApplicationDevelopment;
