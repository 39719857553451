import React from 'react';
import Header from '../header';
import Footer from '../footer';
import cloudservices from '../../src/assets/images/saas-concept-collage.jpg'


const CloudServices = () => {
    return (       
      <div>
         <Header/>
        <img src={cloudservices} alt="About Knackhook" style={{width:'100%',objectFit:'cover',height:'350px'}} class="about-images"/>
<div className="container mt-5">
    <div class="card p-4" 
    style={{
        backgroundColor: "transparent",
        backgroundImage: "radial-gradient(at center top, rgba(55, 156, 255, 0.4) 0%, rgb(3, 5, 16) 88%)",
        borderStyle: "solid",
        borderWidth:"1",
        borderColor: "rgba(55, 156, 255, 0.99)",
        borderRadius: "20",
        boxShadow: "rgba(55, 156, 255, 0.99) 0px 0px 10px 0px"
    }}>
<h3 style={{color:"#379CFFFC"}}>Cloud Services</h3>
<p className='mt-3' style={{color:"#fff"}}>"KnackHook delivers customized cloud services with a focus on scalability, security, and reliability. Our expertise covers AWS, Azure, Google Cloud, and more."</p>
<p className='mt-3' style={{color:"#fff"}}> We specialize in providing cutting-edge cloud solutions to empower businesses of all sizes. With our comprehensive suite of services, we aim to simplify your IT infrastructure, boost productivity, and drive innovation.</p>
</div>

<h1 Alt="Explore our cloud services" className='mt-5' style={{fontSize:'25px',color:'#fff'}}>Explore our range of cloud services below:</h1>
<div className='bg-img mt-3 mb-5'>
    <ul><h5 style={{color:"#379CFFFC"}}>Infrastructure as a Service (IaaS):</h5>
        <li style={{color:"#fff"}}>
        Scale your operations effortlessly with KnackHook's virtualized computing resources. Eliminate hardware constraints and enjoy unparalleled flexibility and scalability as you grow your business.
        </li>
    </ul>
    <ul><h5 style={{color:"#379CFFFC"}}>Platform as a Service (PaaS):</h5>
        <li style={{color:"#fff"}}>
        Accelerate your application development and deployment with KnackHook's robust platform. Our expert team manages the underlying infrastructure, allowing you to focus entirely on building exceptional software solutions.
        </li>
    </ul>
    <ul><h5 style={{color:"#379CFFFC"}}>Software as a Service (SaaS):</h5>
        <li style={{color:"#fff"}}>
        Access powerful software applications seamlessly with KnackHook's SaaS offerings. Say goodbye to installation and maintenance hassles as you streamline your workflows and gain a competitive edge.
        </li>
    </ul>
    <ul><h5 style={{color:"#379CFFFC"}}>Data Storage and Backup:</h5>
        <li style={{color:"#fff"}}>
        Safeguard your critical data with KnackHook's secure and reliable storage solutions. Our advanced backup services ensure that your information remains protected and easily accessible whenever you need it.
        </li>
    </ul>
    <ul><h5 style={{color:"#379CFFFC"}}>Database Services:</h5>
        <li style={{color:"#fff"}}>
        Optimize your data management processes with KnackHook's managed database services. From data retrieval to advanced analytics, our expertise ensures that your database operations run smoothly and efficiently.
        </li>
    </ul>
</div>
</div>
<Footer/>
</div>
);
};

export default CloudServices;
