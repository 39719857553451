import React from 'react';
import Header from '../header';
import Footer from '../footer';
import le from "../assets/images/Lelogo-2 copy.6e53ba81e17e8fccce28.jpg";
import siginsoft from "../assets/images/signinsoft.png";
import ecommerce from "../assets/images/ecommerce.png";
import datamanagement from "../assets/images/datamanagement.png";
import mobileapp from "../assets/images/mobileapp.png";
import cloudapp from "../assets/images/cloudapp.png";
import enterprise from "../assets/images/enterprise.png";
import client from "../assets/images/client.png";




const Portfolio = () => {
    return (
<div>
    <Header/>
    <section  id="banner" style={{ 
      backgroundColor: 'transparent',
      backgroundImage: 'radial-gradient(at bottom right, #3758FF7D 0%, #030510 64%)'
    }}>
   <div class="container pt-5">
      <div class="elementor-background-overlay" style={{
  backgroundImage: 'url(https://kit.baliniz.com/virtty/wp-content/uploads/sites/55/2023/05/noise.png)',
  backgroundPosition: 'center center',
  backgroundRepeat: 'repeat',
  backgroundSize: '14% auto',
  opacity: 0.5,
  transition: 'background 0.3s, border-radius 0.3s, opacity 0.3s'
}}></div>
    
         
   
         <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
               <div class="text-center">
               <h2 class="head3b">
            <span class="dynamic-wrapper style-gradient">
            <span class="dynamic-text" >Welcome to KnackHook's Portfolio</span> 
         </span>
         <p style={{fontSize:"17px"}} class="mt-3">At KnackHook, we take pride in our diverse portfolio of innovative solutions crafted to meet the unique needs of our clients. Our commitment to excellence and client satisfaction shines through in every project we undertake. Explore our portfolio to discover how KnackHook has helped businesses like yours achieve their goals and drive success in the digital landscape.</p>
                 
         <span class="normal-text style-gradient" ></span>
         </h2>

               </div>
            </div>
           
         
         </div>
   </div>
   </section>
   <div className='container'>
    <div className='row mt-5'>
        <div className='col-sm-12 col-lg-4 col-xl-4 col-md-6'>
<img alt='enterprise img' src={enterprise}style={{borderRadius:'30px',borderColor:'#379cff',marginTop:'30px',boxShadow:'#379cff 0px 0px 16px 0px'}} height={100} />
        </div>
        <div className='col-sm-12 col-lg-8 col-xl-8 col-md-6'>
                <h1 Alt="Enterprise Application Development"style={{color:'#379CFF',marginTop:'30px',fontSize:'20px'}}>Enterprise Application Development</h1>
        <p>      Discover our enterprise application development projects designed to streamline business processes, enhance productivity, and facilitate collaboration across organizations. From scalable ERP systems to mission-critical software solutions, KnackHook's expertise ensures seamless automation and efficiency.
</p>

        </div>

    </div>
    <hr style={{color:"white"}}/>
    {/* <div className='row  mt-5'>
        <div className='col-sm-12 col-lg-4 col-xl-4 col-md-6'>
<img src={khianshub} height={200}/>
        </div>
        <div className='col-sm-12 col-lg-8 col-xl-8 col-md-6'>
        <p>   KhiansHub is your one-stop solution for all your employment needs. As an employee, you can
add tasks, leaves, join letters, hike details, and more on the KhiansHub platform. It's designed
to streamline your work life and make managing your career more efficient.

      </p>  </div>

    </div> */}
    <div className='row  mt-5'>
        <div className='col-sm-12 col-lg-4 col-xl-4 col-md-6'>
<img src={ecommerce}style={{borderRadius:'30px',borderColor:'#379cff',marginTop:'30px',boxShadow:'#379cff 0px 0px 16px 0px'}} height={100}/>
        </div>
        <div className='col-sm-12 col-lg-8 col-xl-8 col-md-6'>
        <h5 style={{color:'#379CFF',marginTop:'30px'}}>E-commerce Development</h5>

        <p>   Explore our e-commerce development portfolio showcasing visually stunning and user-friendly online stores, marketplaces, and platforms. KnackHook specializes in creating engaging digital storefronts that drive sales, optimize conversion rates, and elevate customer experiences.

      </p>  </div>

    </div>
    <hr style={{color:"white"}}/>
    <div className='row  mt-5'>
        <div className='col-sm-12 col-lg-4 col-xl-4 col-md-6'>
<img src={cloudapp}style={{borderRadius:'30px',borderColor:'#379cff',marginTop:'30px',boxShadow:'#379cff 0px 0px 16px 0px'}} height={100}/>
        </div>
        <div className='col-sm-12 col-lg-8 col-xl-8 col-md-6'>
        <h5 style={{color:'#379CFF',marginTop:'30px'}}>Cloud Application Development</h5>

        <p> Delve into our cloud application development projects leveraging AWS, Azure, and Google Cloud technologies. KnackHook's cloud-native applications are built to be scalable, resilient, and cost-effective, empowering businesses to innovate and scale rapidly in the digital era.
      </p>  </div>

    </div>
    <hr style={{color:"white"}}/>
    <div className='row  mt-5'>
        <div className='col-sm-12 col-lg-4 col-xl-4 col-md-6'>
<img src={mobileapp} style={{borderRadius:'30px',borderColor:'#379cff',marginTop:'30px',boxShadow:'#379cff 0px 0px 16px 0px'}}height={100}/>
        </div>
        <div className='col-sm-12 col-lg-8 col-xl-8 col-md-6'>
        <h5 style={{color:'#379CFF',marginTop:'30px'}}>Mobile App Development</h5>

        <p>Experience our mobile app development expertise through a range of projects spanning iOS, Android, and cross-platform development. From prototyping and MVP development to full-scale deployment, KnackHook accelerates your mobile strategy with dynamic and engaging mobile applications.
      </p>  </div>

    </div>
    <hr style={{color:"white"}}/>
   
    
    <div className='row  mt-5'>
        <div className='col-sm-12 col-lg-4 col-xl-4 col-md-6'>
<img src={datamanagement} style={{textAign:'center',borderRadius:'30px',borderColor:'#379cff',marginTop:'30px',boxShadow:'#379cff 0px 0px 16px 0px'}}height={100}/>
        </div>
        <div className='col-sm-12 col-lg-8 col-xl-8 col-md-6'>
        <h5 style={{color:'#379CFF',marginTop:'30px'}}>Data Management Solutions</h5>

        <p>Unlock the power of data with KnackHook's data management solutions, including data integration, warehousing, and visualization platforms. Explore how our customized solutions ensure data consistency, reliability, and actionable insights for informed decision-making and business growth. </p>  </div>

    </div>
    <hr style={{color:"white"}}/>
  
    <div>
        <h5>Get in Touch</h5>
        <p>

        Ready to embark on your next digital transformation journey? Contact KnackHook today to discuss how our expertise and innovative solutions can help propel your business forward. Let's collaborate to turn your vision into reality and achieve success together.
        </p>
        </div>
   </div>
    <Footer/>
</div>
        );
};

export default Portfolio;