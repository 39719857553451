import React, { useState ,useEffect} from 'react';
import image1 from '../../src/assets/images/contact1.png'
import Header from '../header';
import Footer from '../footer';
const ContactUs = () => {
 
  
  return (
    <div>
     <Header/>
     <section  id="banner" style={{ 
      backgroundColor: 'transparent',
      backgroundImage: 'radial-gradient(at bottom right, #3758FF7D 0%, #030510 64%)'
    }}>
   <div class="container pt-5">
      <div class="elementor-background-overlay" style={{
  backgroundImage: 'url(https://kit.baliniz.com/virtty/wp-content/uploads/sites/55/2023/05/noise.png)',
  backgroundPosition: 'center center',
  backgroundRepeat: 'repeat',
  backgroundSize: '14% auto',
  opacity: 0.5,
  transition: 'background 0.3s, border-radius 0.3s, opacity 0.3s'
}}></div>
    
         
   
         <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
               <div class="text-center">
               <h2 class="head3b">
            <span class="dynamic-wrapper style-gradient">
            <span class="dynamic-text" >Get in Touch</span> 
         </span>
         <p style={{fontSize:"17px"}} class="mt-3">KnackHook is a dynamic company specializing in innovative solutions for digital marketing, web development, and graphic design. With a focus on creativity and client satisfaction, we craft engaging strategies and designs tailored to meet your business needs. From branding to online presence, we're your partner in success</p>
                 
         <span class="normal-text style-gradient" ></span>
         </h2>

               </div>
            </div>
           
         
         </div>
   </div>
   </section>
   <div id="Contact" class="container mt-5">
      <div class="head2a"style={{ textAlign: 'center' }}>
         <h2 class="head2b" >
            <span class="dynamic-wrapper style-gradient">
            <span class="dynamic-text" >Contact Us
            </span></span>
            <span class="normal-text style-gradient" ></span>
         </h2>
      </div>
      <div class="row mt-5">
         <div class="col-sm-12 col-md-9 col-xl-9 col-lg-9">
            <div class="accordion accordion-flush mt-5" id="accordionFlushExample" >
               <div class="accordion-item" >
                 <h2 class="accordion-header" id="flush-headingOne">
                   <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                   US
                   </button>
                 </h2>
                 <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                   <div class="accordion-body">
                     <div class="row">
                        <div class="col-sm-12 col-lg-4 col-xl-4 col-md-4">
                           <div class="">
                              {/* <h6 style={{ fontSize: '15px' }}>Address</h6> */}
                              <p style={{ color: 'white' }}><i style={{color: '#379CFF',marginRight:"10px" }}class="fa fa-map-marker"></i>KNACKHOOK LLC
<br/>1420 156TH AVE NE SUITE F,
<br/>BELLEVUE, WA 98007-4421.</p>
                                
                            <p  style={{ color: 'white' }}> <i style={{color: '#379CFF',marginRight:"10px" }} class="fa fa-envelope"></i>info@knackhook.com</p>
                             <p style={{ color: 'white' }}> <i style={{color: '#379CFF',marginRight:"10px" }}class="fa fa-phone"></i>+1 (425) 548-6321</p>
                              </div>
                        </div>
                        
                     </div>
                   </div>
                 </div>
               </div>
               
             </div>
   
   
             <div class="accordion accordion-flush mt-4" id="accordionFlushExample" >
               <div class="accordion-item" >
                 <h2 class="accordion-header" id="flush-headingtwo">
                   <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsetwo" aria-expanded="false" aria-controls="flush-collapsetwo">
                   India
                   </button>
                 </h2>
                 <div id="flush-collapsetwo" class="accordion-collapse collapse" aria-labelledby="flush-headingtwo" data-bs-parent="#accordionFlushExample">
                   <div class="accordion-body">
                     <div class="row">
                        <div class="col-sm-12 col-lg-4 col-xl-4 col-md-4">
                           <div class="">
                              {/* <h6 style={{ fontSize: '15px' }}>Address</h6> */}
                             <p style={{ color: 'white' }}> <i style={{color: '#379CFF',marginRight:"10px" }}class="fa fa-map-marker"></i>Plot No 92A, Flat No 301,
                                 Kondapur, Hyderabad-500084.</p>
                                <p style={{ color: 'white' }}><i style={{color: '#379CFF',marginRight:"10px" }} class="fa fa-envelope"></i>info@knackhook.com</p>
                                 <p  style={{ color: 'white' }}> <i style={{color: '#379CFF',marginRight:"10px" }}class="fa fa-phone"></i>+91 (868) 862-3071</p>
                           </div>
                        </div>
                     </div>
                   </div>
                 </div>
               </div>
               
             </div>
   
   
             {/* <div class="accordion accordion-flush mt-4" id="accordionFlushExample" >
               <div class="accordion-item" >
                 <h2 class="accordion-header" id="flush-headingthree">
                   <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsethree" aria-expanded="false" aria-controls="flush-collapsethree">
                     UK
                   </button>
                 </h2>
                 <div id="flush-collapsethree" class="accordion-collapse collapse" aria-labelledby="flush-headingthree" data-bs-parent="#accordionFlushExample">
                   <div class="accordion-body">
                     <div class="row">
                        <div class="col-sm-12 col-lg-4 col-xl-4 col-md-4">
                           <div class="">
                           <p style={{ color: 'white' }}><i style={{color: '#379CFF',marginRight:"10px" }}class="fa fa-map-marker"></i>18666 Redmond Way,
                             Redmond, WA, 98052.</p>
                                
                            <p  style={{ color: 'white' }}> <i style={{color: '#379CFF',marginRight:"10px" }} class="fa fa-envelope"></i>info@knackhook.com</p>
                             <p style={{ color: 'white' }}> <i style={{color: '#379CFF',marginRight:"10px" }}class="fa fa-phone"></i>+1 425-540-7888</p>
                           
                           </div>
                        </div>
                     </div>
                   </div>
                 </div>
               </div>
               
             </div> */}
         </div>
         <div class="col-sm-12 col-md-12 col-xl-3 col-lg-3 text-center" >
            <img src={image1}  class="contactimage"alt="contact" />
         </div>
      </div>
     
   </div>
   <Footer/>
   </div>
  );
};

export default ContactUs;
