import React, { useState ,useEffect} from 'react';
import image1 from '../../src/assets/images/about-us.png'
import Header from '../header';
import Footer from '../footer';

const Privacyterms = () => {
 
  
  return (
    <div>
        <Header/>
        <div class="container">
    <h3  style={{color:"#379cff"}}class="terms">Privacy Policy</h3>
    <p >Welcome to KnackHook!</p>

    <p >KnackHook provides customized, scalable, easy-to-use programming solutions to meet your business management challenges. With special focus on information access and distribution, our solutions will improve efficiency at moderate cost for both internal and external clients. We understand what it takes to design, develop and implement enterprise level IT applications.</p>

    <p >Our Client First Mantra is driven by business practices that are flexible and focused on always doing what’s right for our clients. We listen, we understand and we deliver. We dive into your business with open minds and fresh eyes to analyze your industry, the competition and market trends that shape your landscape.</p>

    <h3 style={{color:"#379cff"}} class="terms">What we do:</h3>
    <p >KnackHook delivers the services in Software Development, Educational Services, Recruitment Services, and Software Testing Services. We follow the standards of IT industry to deliver the quality services i.e. Agile process wherein the development would be of build-test-build till moving it to the production.</p>

    <h3 style={{color:"#379cff"}} class="terms">Lean is In:</h3>
    <p >We are a big fan of lean principles. Instead of spending hours on Photoshop designing and coding, we sketch and prototype solutions first to have your approval before we move on to the more concrete stuff. We also consult and help startups to follow the lean strategy.</p>

    <h3 style={{color:"#379cff"}} class="terms">Best Practices:</h3>
    <p >While your satisfaction is our endeavor, we ensure that our solutions deliver the right results for you, which will make sure you get better return on investment.</p>

    <h3 style={{color:"#379cff"}} class="terms">Simplicity:</h3>
    <p >We love to stay simple and easy. So do the users. By letting them enjoy the luxury of receiving the right information and have access to an eye pleasing environment with simplicity. How is that for user friendliness? All our development starts with the aim of letting simplicity take the front seat and that is how it stays through the course of the project.</p>
</div>


    <Footer/>
    </div>
  );
};

export default Privacyterms;
