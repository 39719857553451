import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../Home/Home';
import CloudServices from '../Home/CloudServices';
import Privacyterms from '../Home/privacy';
import Consulting from '../Home/Consulting';
import ApplicationDevelopment from '../Home/ApplicationDevelopment';
import ArtificialIntelligence from '../Home/ArtificialIntelligence';
import EnterpriseSolutions from '../Home/EnterpriseSolutions';
import DataAnalytics from '../Home/DataAnalytics';
import JobOpenings from '../Home/JobOpenings'; // Fixed the import statement
import CaseStudies from '../Home/CaseStudies';
import Portfolio from '../Home/Portfolio';
import Projects from '../Home/Projects';
import AboutUs from '../Home/AboutUs';
import ContactUs from '../Home/ContactUs';

const Knackroutes = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Home" element={<Home />} />
        <Route path="/Privacy&Policy" element={<Privacyterms />} />
        <Route path="/cloudservices" element={<CloudServices />} />
        <Route path="/applicationdevelopment" element={<ApplicationDevelopment />} />
        <Route path="/dataanalytics" element={<DataAnalytics />} />
        <Route path="/artificialintelligence" element={<ArtificialIntelligence />} />
        <Route path="/enterprisesolutions" element={<EnterpriseSolutions />} />
        <Route path="/Consulting" element={<Consulting />} />
        <Route path="/jobopenings" element={<JobOpenings />} />
        <Route path="/casestudies" element={<CaseStudies />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/projects" element={<Projects />} />
      </Routes>
    </div>
  );
};

export default Knackroutes;
