import React from 'react';
import Header from '../header';
import Footer from '../footer';
import image1 from '../../src/assets/images/baner1 (1).jpg'
import applicationimage from '../../src/assets/images/applicationdevelopment.jpg'
import artificialinteligence from '../../src/assets/images/artificialinteligence.jpg'
import dataanalytics from '../../src/assets/images/dataanalytics.jpg'
import caseone from '../../src/assets/images/case1.png'
import casetwo from '../../src/assets/images/infrastructure with cloud migration.png'
import casethree from '../../src/assets/images/online sales with e commerece.png'
import casefour from '../../src/assets/images/data insights with bisiness growth.png'
import casefive from '../../src/assets/images/enchance with user experience withcustom mobile app.png'


const CaseStudies = () => {
    return (
        <div>
        <Header/>
        <section  id="banner" style={{ 
      backgroundColor: 'transparent',
      backgroundImage: 'radial-gradient(at bottom right, #3758FF7D 0%, #030510 64%)'
    }}>
   <div class="container pt-5">
      <div class="elementor-background-overlay" style={{
  backgroundImage: 'url(https://kit.baliniz.com/virtty/wp-content/uploads/sites/55/2023/05/noise.png)',
  backgroundPosition: 'center center',
  backgroundRepeat: 'repeat',
  backgroundSize: '14% auto',
  opacity: 0.5,
  transition: 'background 0.3s, border-radius 0.3s, opacity 0.3s'
}}></div>
    
         
         <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
               <div class="text-center">
               <h2 class="head3b">
            <span class="dynamic-wrapper style-gradient">
            <span class="dynamic-text" >Explore KnackHook's Success Stories</span> 
         </span>
         <p style={{fontSize:"17px"}} class="mt-3">"Welcome to KnackHook's Case Studies page, where we highlight our successful collaborations with clients across various industries. Dive into these real-world examples to discover how KnackHook's innovative solutions have transformed businesses, solved challenges, and driven tangible results."</p>
                 
         <span class="normal-text style-gradient" ></span>
         </h2>

               </div>
            </div>
           
         
         </div>
   </div>
   </section>
   <div className="container mb-5">
    <div className="card p-5 mt-5" style={{backgroundColor:"transparent",border: "1px solid gray",borderRadius:"30px"}}>
        <div className="row mt-5">
        <div className="col-sm-12 col-lg-6 col-xl-6 col-md-6">
        <div class="row mt-4">
                <div class="col-sm-12 col-lg-4 col-xl-4 col-md-12">
                <img  class="img-fluid" src={caseone} alt="KnackHook" style={{height:'160px',borderRadius:'20px 20px 20px 20px'}}/>

                </div>
                <div class="col-sm-12 col-lg-8 col-xl-8 col-md-12">
                   <h1 Alt="Streamlining Operations with Custom ERP Solution" style={{fontSize:'18px',verticalAlign:'middle',fontSize:'20px',color:'#fff'}}>Streamlining Operations with Custom ERP Solution</h1>
               <p>In this case study, learn how KnackHook partnered with a leading manufacturing company to develop a custom Enterprise Resource Planning (ERP) solution. By leveraging our expertise in enterprise application development, we helped the client streamline their operations, optimize supply chain management, and improve decision-making processes. Read more to see how our tailored ERP solution transformed their business.</p> 
               </div>
               </div>
        </div>
        <div className="col-sm-12 col-lg-6 col-xl-6 col-md-6">
        <div class="row mt-4">
                <div class="col-sm-12 col-lg-4 col-xl-4 col-md-12">
                <img  class="img-fluid" src={casetwo} alt="KnackHook" style={{height:'160px',borderRadius:'20px 20px 20px 20px'}}/>

                </div>
                <div class="col-sm-12 col-lg-8 col-xl-8 col-md-12">
                   <h5 style={{fontSize:'18px',verticalAlign:'middle'}}>Optimizing Infrastructure with Cloud Migration</h5>
               <p>In this case study, explore how KnackHook partnered with a multinational corporation to migrate their infrastructure to the cloud. Through our cloud application development and migration services, we helped the client achieve scalability, agility, and cost savings. From architecture design to implementation, our expertise ensured a seamless transition to the cloud, driving efficiency and innovation across the organization. Dive into the case study to see the benefits of cloud adoption.</p> 
               </div>
               </div>
                        
              
        </div>
        </div>
        <div className='row mt-5'>
        <div className="col-sm-12 col-lg-6 col-xl-6 col-md-6">
        <div class="row mt-4">
                <div class="col-sm-12 col-lg-4 col-xl-4 col-md-12">
                <img  class="img-fluid" src={casethree} alt="KnackHook" style={{height:'160px',borderRadius:'20px 20px 20px 20px'}}/>

                </div>
                <div class="col-sm-12 col-lg-8 col-xl-8 col-md-12">
                   <h5 style={{fontSize:'18px',verticalAlign:'middle'}}>Driving Online Sales with E-commerce Platform Optimization</h5>
               <p>Discover how KnackHook collaborated with a growing e-commerce startup to optimize their online sales platform. Through our e-commerce development services, we enhanced the user experience, implemented advanced analytics, and personalized marketing strategies. As a result, the client experienced a significant increase in conversions, revenue, and customer satisfaction. Explore the full case study to learn about our journey to success.</p> 
               </div>
               </div>
</div>
<div className="col-sm-12 col-lg-6 col-xl-6 col-md-6">
<div class="row mt-4">
                <div class="col-sm-12 col-lg-4 col-xl-4 col-md-12">
                <img  class="img-fluid" src={casefour} alt="KnackHook" style={{height:'160px',borderRadius:'20px 20px 20px 20px'}}/>

                </div>
                <div class="col-sm-12 col-lg-8 col-xl-8 col-md-12">
                   <h5 style={{fontSize:'18px',verticalAlign:'middle'}}>Harnessing Data Insights for Business Growth</h5>
               <p>Explore how KnackHook partnered with a financial services firm to unlock the power of data analytics. Through our data management and analytics solutions, we helped the client gain actionable insights, identify trends, and make informed decisions. From data integration to predictive modeling, our comprehensive approach empowered the client to drive business growth and stay ahead of the competition. Read the case study to see how data became their strategic asset.</p> 
               </div>
               </div>
</div>
            </div>
        <div className="row mt-5">
        <div className="col-sm-12 col-lg-6 col-xl-6 col-md-6">
        <div class="row mt-4">
                <div class="col-sm-12 col-lg-4 col-xl-4 col-md-12">
                <img  class="img-fluid" src={casefive} alt="KnackHook" style={{height:'160px',borderRadius:'20px 20px 20px 20px'}}/>

                </div>
                <div class="col-sm-12 col-lg-8 col-xl-8 col-md-12">
                   <h5 style={{fontSize:'18px',verticalAlign:'middle'}}>Enhancing User Experience with Custom Mobile App</h5>
               <p>Learn how KnackHook collaborated with a leading healthcare provider to develop a custom mobile application for patient engagement. Our mobile app development services focused on usability, accessibility, and security, resulting in an intuitive and feature-rich app that improved patient outcomes and satisfaction. Discover how our innovative solution transformed healthcare delivery in this compelling case study.</p> 
               </div>
               </div>
        </div>
        
      

</div>
    </div>


    <div className="mt-5">
        <h5>Get Inspired by KnackHook's Success Stories</h5>
        <p>These case studies are just a glimpse into the transformative impact of KnackHook's services. Whether it's enterprise application development, e-commerce solutions, data analytics, or cloud migration, we're committed to delivering innovative solutions that drive business success. Explore our full collection of case studies to get inspired and see how KnackHook can help you achieve your goals.</p>
    </div>
   </div>
   <Footer/>
        </div>
);
};

export default CaseStudies;