import React, { useState ,useEffect} from 'react';
import image1 from '../../src/assets/images/about-us.png'
import {Link} from 'react-router-dom';


const About = () => {
 
   const [clicked, setClicked] = useState(false);
  
  return (
   <div id="About">
    <div  class="container mt-5">
         <h4>Do you want to transform your business, with minimal risk and disruption?</h4>
         <div class="row">
            <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12">
               <p>KnackHook, the Epitome of Insight, was forged by a consortium of adept IT visionaries in 2016. Our mission: to unearth the essence and amplify the worth of Organizational Assets and investments.</p>
               <p>Innovation fuels our global delivery model, powered by an unyielding commitment to client contentment. Allow us to wield our IT prowess, strategic consultancy, and business-process expertise to revolutionize your enterprise, minimizing risk and maximizing seamless progression.</p>
               <div class="row mt-4">
                  <div class="col-lg-4 col-xl-4 col-md-4 col-sm-4">
                  <Link to="/AboutUs">  <button type="button" class="btn btn-primary mt-2" onClick={() => setClicked(true)}>About Us<i class="fa fa-arrow-right ml-5" style={{
  color: '#379CFF',
  marginLeft: '15px'
}} aria-hidden="true"></i></button>  </Link>                </div>
                  <div class="col-lg-4 col-xl-4 col-md-4 col-sm-4">
                     <div class="text-center">
                        <h5>98 <span class="symbol">%</span></h5>
                        <p>Client Satisfaction</p>
                     </div>
                  </div>
                  <div class="col-lg-4 col-xl-4 col-md-4 col-sm-4">
                     <div class="text-center">
                        <h5>30<span class="symbol">+</span></h5>
                        <p>Global Partnerships</p>
                     </div>
                  </div>
               </div>
            </div>
            <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12">
               <div class="text-center">
                  <img src={image1} alt="About Knackhook"  class="about-images"/>
               </div>
            </div>
         </div>
      </div>
      </div>
  );
};

export default About;
