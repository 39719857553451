import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import Cloudservices from './CloudServices';


const Service = () => {
 
    const [clicked, setClicked] = useState(false);


  return (
   
    <div id="Services" className="container mt-5">
    <div class="row">
       <div class="col-1">
          <div class="head-a">
             <div class="head-b" >
                <h5 class="elementor-heading-title elementor-size-default" style={{color: "#379CFFFC"}}>SERVICES</h5>
             </div>
          </div>
       </div>
       <div class="col-11">
          <div>
             <div class="head2a"style={{textAlign:"left"}}>
                <h1 class="head2b" >
                   <span class="dynamic-wrapper style-gradient">
                   <span class="dynamic-text" >Are You Passionate About Quality Of Services?</span></span>
                   <span class="normal-text style-gradient" ></span>
                </h1>
             </div>
          </div>
       </div>
    </div>
      <div className="row mt-3">
        
  <div className="col-xl-4 col-lg-4 col-sm-12 col-md-6 mt-3">
      <div
        className="card text-center mt-3"
        style={{
          margin: '0px',
          padding: '10px',
          backgroundColor: 'transparent',
          backgroundImage:
            'radial-gradient(at top center, #379CFF66 0%, rgb(3 5 16) 88%)',
          borderStyle: 'solid',
          borderWidth: '1px',
          borderColor: '#379CFFFC',
          borderRadius: '20px',
          height: '250px',
          boxShadow:
            '0px 0px 10px 0px rgba(54.99999999999997, 156.00000000000023, 255, 0.9882352941176471)',
        }}
      >
        {/* Front side content */}
        <h6 class="mt-3">Cloud Services</h6>
        <p class="mt-2">We provide cutting-edge cloud solutions tailored to your business needs, ensuring scalability, security, and reliability.</p>
        <div>
        <Link to="/CloudServices" > <button type="button" className="btn btn-primary mt-2" onClick={() => setClicked(true)}>
        More 
        <i className="fa fa-arrow-right ml-5" style={{ color: '#379CFF', marginLeft: '15px' }} aria-hidden="true"></i>
      </button></Link>
    </div>
      </div>
    
  </div>


  <div className="col-xl-4 col-lg-4 col-sm-12 col-md-6 mt-3" >
      <div
        className="card text-center mt-3"
        style={{
          margin: '0px',
          padding: '10px',
          backgroundColor: 'transparent',
          backgroundImage:
            'radial-gradient(at top center, #379CFF66 0%, rgb(3 5 16) 88%)',
          borderStyle: 'solid',
          borderWidth: '1px',
          borderColor: '#379CFFFC',
          borderRadius: '20px',
          height: '250px',
          boxShadow:
            '0px 0px 10px 0px rgba(54.99999999999997, 156.00000000000023, 255, 0.9882352941176471)',
        }}
      
      >
        {/* Front side content */}
        <h6 class="mt-3">Enterprise Solutions</h6>
        <p class="mt-2">We provide comprehensive enterprise solutions tailored to optimize your business processes, enhance productivity, and drive growth.</p>
        <div>
        <Link to="/EnterpriseSolutions" >  <button type="button" class="btn btn-primary mt-2" onClick={() => setClicked(true)}>More<i class="fa fa-arrow-right ml-5" style={{ color: '#379CFF', marginLeft: '15px' }} aria-hidden="true"></i></button>
        </Link>
        </div>
      </div>
      
  </div>


       
  <div className="col-xl-4 col-lg-4 col-sm-12 col-md-6 mt-3" >
      <div
        className="card text-center mt-3"
        style={{
          margin: '0px',
          padding: '10px',
          backgroundColor: 'transparent',
          backgroundImage:
            'radial-gradient(at top center, #379CFF66 0%, rgb(3 5 16) 88%)',
          borderStyle: 'solid',
          borderWidth: '1px',
          borderColor: '#379CFFFC',
          borderRadius: '20px',
          height: '250px',
          boxShadow:
            '0px 0px 10px 0px rgba(54.99999999999997, 156.00000000000023, 255, 0.9882352941176471)',
        }}
      >
        {/* Front side content */}
        <h6 class="mt-3">Data and Analytics</h6>
        <p class="mt-2">We specialize in extracting valuable insights from data, enabling informed decision-making and driving business growth.</p>
        <div>
        <Link to="/DataAnalytics">   <button type="button" class="btn btn-primary mt-2" onClick={() => setClicked(true)}>More<i class="fa fa-arrow-right ml-5" style={{ color: '#379CFF', marginLeft: '15px' }} aria-hidden="true"></i></button>
        </Link>
        </div>
      </div>
     
  </div>


      </div>
      <div className="row mt-5">
        
       
  <div className="col-xl-4 col-lg-4 col-sm-12 col-md-6 mt-3" >
      <div
        className="card text-center mt-3"
        style={{
          margin: '0px',
          padding: '10px',
          backgroundColor: 'transparent',
          backgroundImage:
            'radial-gradient(at top center, #379CFF66 0%, rgb(3 5 16) 88%)',
          borderStyle: 'solid',
          borderWidth: '1px',
          borderColor: '#379CFFFC',
          borderRadius: '20px',
          height: '250px',
          boxShadow:
            '0px 0px 10px 0px rgba(54.99999999999997, 156.00000000000023, 255, 0.9882352941176471)',
        }}
      >
        {/* Front side content */}
        <h6 class="mt-3">Consulting</h6>
        <p class="mt-2">We provide expert consulting services to help businesses navigate challenges, make informed decisions, and achieve strategic goals.</p>
        <div>
        <Link to="/Consulting" >  <button type="button" class="btn btn-primary mt-2" onClick={() => setClicked(true)} >More<i class="fa fa-arrow-right ml-5" style={{ color: '#379CFF', marginLeft: '15px' }} aria-hidden="true"></i></button>
       
       </Link> </div>
      </div>
     
  </div>

  <div className="col-xl-4 col-lg-4 col-sm-12 col-md-6 mt-3" >
      <div
        className="card text-center mt-3"
        style={{
          margin: '0px',
          padding: '10px',
          backgroundColor: 'transparent',
          backgroundImage:
            'radial-gradient(at top center, #379CFF66 0%, rgb(3 5 16) 88%)',
          borderStyle: 'solid',
          borderWidth: '1px',
          borderColor: '#379CFFFC',
          borderRadius: '20px',
          height: '250px',
          boxShadow:
            '0px 0px 10px 0px rgba(54.99999999999997, 156.00000000000023, 255, 0.9882352941176471)',
        }}
      >
        {/* Front side content */}
        <h6 class="mt-3">Application Development</h6>
        <p class="mt-2">We excel in crafting tailored applications that meet your unique needs, leveraging the latest technologies and best practices.</p>
        <div>
        <Link to="/ApplicationDevelopment">   <button type="button" class="btn btn-primary mt-2" onClick={() => setClicked(true)}>More<i class="fa fa-arrow-right ml-5" style={{ color: '#379CFF', marginLeft: '15px' }} aria-hidden="true"></i></button>
        </Link>
        </div>
      </div>
   
  </div>
  <div className="col-xl-4 col-lg-4 col-sm-12 col-md-6 mt-3">
      <div
        className="card text-center mt-3"
        style={{
          margin: '0px',
          padding: '10px',
          backgroundColor: 'transparent',
          backgroundImage:
            'radial-gradient(at top center, #379CFF66 0%, rgb(3 5 16) 88%)',
          borderStyle: 'solid',
          borderWidth: '1px',
          borderColor: '#379CFFFC',
          borderRadius: '20px',
          height: '250px',
          boxShadow:
            '0px 0px 10px 0px rgba(54.99999999999997, 156.00000000000023, 255, 0.9882352941176471)',
        }}
      >
        {/* Front side content */}
        <h6 class="mt-3">Artificial Intelligence (AI)</h6>
        <p class="mt-2">We leverage cutting-edge AI technologies to provide solutions that enhance automation, decision-making, and business intelligence.</p>
        <div>
        <Link to="/ArtificialIntelligence">  
          <button type="button" class="btn btn-primary mt-2" onClick={() => setClicked(true)}>More<i class="fa fa-arrow-right ml-5" style={{ color: '#379CFF', marginLeft: '15px' }} aria-hidden="true"></i></button>
        </Link>
        </div>
      </div>
     
  </div>
      </div>
    </div>
   
  );
};



export default Service;
